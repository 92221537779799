<template>
    <ul class="collection">
        <li class="collection-item avatar" v-for="resident in residents" :key="resident.id">
            <img v-if="residentHasAvatar(resident)" class="circle" :src="residentAvatarUrl(resident.avatar)">
            <img v-if="residentHasNoAvatarAndFemale(resident)" class="circle" src="../assets/grandma.png">
            <img v-if="residentHasNoAvatarAndMale(resident)" class="circle" src="../assets/grandpa.png">
            <span class="title">{{ resident.fname }} {{ resident.lname }}</span>
            <p>{{ resident.nickname }}</p>
            <a @click="switchResident(resident.id)" href="#!" class="secondary-content" style="top: 10px !important;" v-if="resident.id != currentActiveResident.id">
                <a class="btn-floating btn-large waves-effect waves-light red"><i class="material-icons">forward</i></a>
            </a>
        </li>
    </ul>
</template>

<script>
export default {
    data() {
        return {
            residents: [],
            currentActiveResident: {}
        }
    },
    mounted() {
        this.residents = this.$store.state.residents.concat().sort((a, b) => (a.fname > b.fname) ? 1 : ((b.fname > a.fname) ? -1 : 0))
        this.currentActiveResident = this.$store.state.residents[this.$store.state.activeResident]
    },
    methods: {
        switchResident(id)
        {
            let activeResidentStoreIndex = this.$store.state.residents.findIndex((resident) => {
                return resident.id == id
            })
            this.$store.commit('SET_ACTIVE_RESIDENT', activeResidentStoreIndex)

            this.$store.dispatch('fetchActiveResidentAccount').then(() => {
                this.$store.dispatch('fetchActiveResidentPosts').then(() => {
                    this.$router.push('/')
                })
            })
        },
        residentHasAvatar(resident) {
            if(resident.media.length > 0) {
                return true
            }
            return false
        },
        residentHasNoAvatarAndFemale(resident) {
            if(!resident.media.length > 0 && resident.sexe == 'female'){
                return true
            }
            return false
        },
        residentHasNoAvatarAndMale(resident) {
            if(!resident.media.length > 0 && resident.sexe == 'male'){
                return true
            }
            return false
        },
    },
    computed: {
    }
}
</script>

<style>

</style>