<template>
  <div class="container">
    <div class="row center-align">
      <img src="../../assets/logo.png">
    </div>

    <div style="text-align: center; margin-bottom: 20px;">
      <h4>{{ $t('login.greeting') }}</h4>
      <h6 v-if="showLoginOptions">{{ $t('login.instructions') }}.</h6>
    </div>

    <div v-if="showLoginOptions">
      <!-- Google Social Login -->
      <div class="row center-align" style="display:flex; justify-content: center;">
        <div id="buttonDiv"></div> 

        <!-- <a class="waves-effect waves-light btn-large social google" id="googleLoginButton">
          <font-awesome-icon :icon="['fab', 'google']" class="fab-social-icon" size="2x"/> {{ $t('login.google') }}
        </a> -->
      </div>

      <!-- Facebook Social Login -->
      <div class="row center-align" style="margin-top: 10px;">
        <a class="waves-effect waves-light btn-large social facebook" @click="facebookContinue">
          <font-awesome-icon :icon="['fab', 'facebook']" class="fab-social-icon" size="2x"/> {{ $t('login.facebook') }}
        </a>
      </div>

      <!-- Manual Registration -->
      
      <div class="row center-align" style="margin-top: 10px;">
        <a class="waves-effect waves-light btn-large social manual" @click="manualRegistration">
          <font-awesome-icon icon="address-card" class="fab-social-icon" size="2x"/> {{ $t('login.email') }}
        </a>
      </div>

      <div class="row center-align">
        <br />
        <router-link :to="{ path: 'privacy' }">{{ $t('settings.privacy') }}</router-link>
      </div>
    </div>

    <!-- Old fashioned Login -->
    <div class="row center-align" v-else>
      <form class="col s12">
        <div clas="row">
          <div class="input-field col s12">
            <input type="email" autocomplete="email" id="email" v-model="email" class="validate">
            <label for="email">{{ $t('login.emailLabel') }}</label>
          </div>
        </div>
        <div clas="row">
          <div class="input-field col s12">
            <input type="password" autocomplete="current-password" id="password" v-model="password" class="validate">
            <label for="password">{{ $t('login.password') }}</label>
          </div>
        </div>
        <div class="row">
          <button
            v-show="!loginLoading"
            type="submit"
            @click.prevent="login()"
            name="login"
            class="col s12 btn btn-large waves-effect pink darken-2"
          >Login</button>
          <div v-show="loginLoading" class="preloader-wrapper small active">
            <div class="spinner-layer spinner-green-only">
              <div class="circle-clipper left">
                <div class="circle"></div>
              </div>
              <div class="gap-patch">
                <div class="circle"></div>
              </div>
              <div class="circle-clipper right">
                <div class="circle"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="row center-align">
          <br />
          <router-link :to="{ path: 'register' }">{{ $t('login.registerText') }}</router-link>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import swal from "sweetalert"
import store from '../../store'
import * as PusherPushNotifications from '@pusher/push-notifications-web'

export default {
  name: "login",
  data() {
    return {
      email: "",
      password: "",
      loginLoading: false,
      personalInvitation: false,
      showLoginOptions: true,
    };
  },
  created() {
  },
  mounted() {

    this.$loadScript("https://accounts.google.com/gsi/client")
      .then(() => {
        // Script is loaded, do something
        google.accounts.id.initialize({
          client_id: process.env.VUE_APP_GOOGLE_OAUTH_CLIENT_ID,
          callback: this.handleGoogleLogin
        });
        google.accounts.id.renderButton(
          document.getElementById("buttonDiv"),
          { theme: "outline", size: "large" }  // customization attributes
        );
      })
      .catch(() => {
        // Failed to fetch script
      });

    // did we receive a name and a code?
    if(this.$route.params.code != undefined && this.$route.params.name != undefined)
    {
      axios.post(process.env.VUE_APP_SERVER_URL + "/api/verify-code", {
        code: this.$route.params.code
      }).then((res) => {
        if(res.status == 200)
        {
          this.personalInvitation = true
        }
      }).catch((err) => {
        console.error(err)
      })
    }

    // check to see if the user is already logged in
    // we can see that in the store getter isLoggedIn...
    if(store.getters.isLoggedIn) {
      store.dispatch('userInformation')
      .then(() => {
        this.loginLoading = true
        this.afterLogin()
      }).catch((error) => {
        console.error(error)
        console.log('we should clear the store and log the user out so we can restart')
      })
    }
  },
  methods: {
    handleGoogleLogin(jwt)
    {
      let that = this
      this.loginLoading = true
      store.commit('LOGIN')
      axios
        .post(process.env.VUE_APP_SERVER_URL + "/api/google-login", {
          authCode: jwt.credential,
          redirect_uri: "social-authenticated"
        })
        .then (response => {
            store.commit('SET_AUTH_INFO', { 'auth_token': response.data.access_token, 'auth_token_type': 'Bearer' })
            store.commit('LOGIN_SUCCESS')
            store.dispatch('userInformation')
              .then(() => {
                that.afterLogin()
              })
              .catch( error => {
                console.error(error)
              })
          })
          .catch(error => {
            console.log(error)
            if(error.response) {
             swal("Error!", error.response.data.message, "error")
            } else {
              swal("Error! We could not make this request to the server")
            }
          });
    },
    manualRegistration() {
      this.showLoginOptions = false
    },
    fb_sdk_loaded() {
      return window.fb_sdk_ready
    },
    googleContinue() {
      let that = this
      this.loginLoading = true
      store.commit('LOGIN')
      this.$gAuth.signIn().then(user => {
        // send authcode to screen signage backend
        axios
          .post(process.env.VUE_APP_SERVER_URL + "/api/google-login", {
            authCode: user.getAuthResponse().id_token,
            redirect_uri: "social-authenticated"
          })
          .then( response => {
            store.commit('SET_AUTH_INFO', { 'auth_token': response.data.access_token, 'auth_token_type': 'Bearer' })
            store.commit('LOGIN_SUCCESS')
            store.dispatch('userInformation')
              .then(() => {
                that.afterLogin()
              })
              .catch( error => {
                console.error(error)
              })
          })
          .catch(error => {
            console.log(error)
            if(error.response) {
             swal("Error!", error.response.data.message, "error")
            } else {
              swal("Error! We could not make this request to the server")
            }
            this.$gAuth
              .signOut()
              .then(() => console.log("ok logged out"))
              .catch(error => console.error(error));
          });
        // console.log(authCode)
      });
    },
    facebookContinue() {
      let that = this
      this.loginLoading = true
      FB.login(function(response) {
        if(response.status === 'connected') {
          axios.post(process.env.VUE_APP_SERVER_URL + "/api/facebook-login", {
            authCode: response.authResponse.accessToken,
            redirect_uri: "social-authenticated"
          })
          .then(response => {
            store.commit('SET_AUTH_INFO', { 'auth_token': response.data.access_token, 'auth_token_type': 'Bearer' })
            store.commit('LOGIN_SUCCESS')
            store.dispatch('userInformation')
              .then(() => {
                that.afterLogin()
              })
              .catch( error => {
                console.error(error)
              })
          })
          .catch(error => {
            console.error(error)
            swal("Error!", error.response.data.message, "error");
          })
        }
      }, {scope: 'public_profile,email'})
    },
    login() {
      this.loginLoading = true;

      this.$store
        .dispatch("login", {
          email: this.email,
          password: this.password
        })
        .then(() => {
          this.$store
            .dispatch("userInformation")
            .then(() => {
              this.afterLogin()
            })
            .catch(error => {
              console.log(error);
            });
        })
        .catch(error => {
          this.loginLoading = false;
          console.error(error)
          if (error.response.status == 401) {
            swal("Error!", error.response.data.message, "error");
          } else {
            console.log(error);
          }
        });
    },
    afterLogin() {
      if(this.personalInvitation)
      {
        // link resident for this users and transport to home page!

        axios.post(`${process.env.VUE_APP_SERVER_URL}/api/validate-sync-code`, { code: this.$route.params.code }, {
          headers: {
              'Authorization': 'Bearer ' + store.state.auth.auth_token
          }
        })
          .then((response) => {
            if(response.status == '404') {
              // this should never happen because Laravel throws a 404 because we use findOrFail
              // javascript goes to the catch block
              // if you are 100% sure this is correct you can delete this if statement...
              console.log('Sorry could not find this resident')
            } else {
              // we should let the store make sure we now have a resident
              this.$store.commit('TOGGLE_KEY_HAS_RESIDENTS', true)
                this.$store.dispatch('fetchLinkedResidents').then(() => {
                  this.$store.dispatch('fetchActiveResidentAccount').then(() => {
                    this.$store.dispatch('fetchActiveResidentPosts').then(() => {
                      this.$router.push('/')
                    })
                  })
                })
            }
          }).catch((error) => {
            this.loginLoading = false;
            console.error('We couldnt even check because of this error.', error)
            this.checkCodeLoading = false
            this.userCode = ''
          })
      }

      this.$store.dispatch('fetchLinkedResidents').then((res) => {
        this.$store.dispatch('fetchActiveResidentAccount').then(() => {
          this.$store.dispatch('fetchActiveResidentPosts').then(() => {
            // check key in store if the user has residents. if so go to / else go to link-resident
            if(this.$store.state.hasResidents) {
              console.log('Login.vue -> Will forward user to homepage')
              this.$router.push('/')
            } else if (this.$store.state.user.account_id !== null) {
              console.log('we will forward you to the home page, it will break because we do not have a resident yet')
              this.$router.push('/')
            } else {
              console.log('Login.vue -> Will forward user to link-resident page')
              this.$router.push('/first-time-link-resident')
            }
          }).catch((err) => {
            this.loginLoading = false;
            console.error(err)
          })
        }).catch((err) => {
          this.loginLoading = false;
          console.error(err)
        })
      }).catch((err) => {
        this.loginLoading = false;
        console.error(err)
        console.log('we transfer to the link-resident view...')
        console.log('Login.vue -> Will forward user to link-resident page because of error')
        this.$router.push('/first-time-link-resident')
      })
    }
  }
};
</script>

<style scoped>
.fab-social-icon {
  padding-top: 8px;
  padding-right: 5px;
}
</style>

