<template>
<div class="container" style="margin-top: 20px;">
    <!-- Autocomplete box to select a resident from the facility. -->
    <div class="row">
        <div class="col s12">
            <div class="row">
                <div class="input-field col s12">
                    <i class="material-icons prefix">textsms</i>
                    <input type="text" id="autocomplete-input" class="autocomplete">
                    <label for="autocomplete-input">Klant:</label>
                </div>
            </div>
        </div>
    </div>

    <div v-if="selectedResident !== null" class="row">
        Geselecteerde resident:
            {{ selectedResident.fname }} {{ selectedResident.lname }}
            <span v-if="selectedResident.partner_id !== null">
                (partner: {{ selectedResident.partner.fname }} {{ selectedResident.partner.lname }})
            </span>
    </div>

    <!-- Create cards of all values in computed value mealsOpenForSubscription -->
    <div class="row">
        <div class="col s12 m6" v-for="meal in mealsOpenForSubscription" :key="meal.id">
            <div class="card">
                <div class="card-image">
                    <img :src="mealImage(meal.media[0])" v-if="meal.media.length > 0">
                    <div v-else>
                    </div>
                    <a class="btn-floating halfway-fab waves-effect waves-light red">
                        <font-awesome-icon v-if="meal.type == 'breakfast'" icon="mug-hot" size="2x" transform="right-4 down-3" />
                        <font-awesome-icon v-if="meal.type == 'lunch'" icon="utensils" size="2x" transform="right-4 down-3" />
                        <font-awesome-icon v-if="meal.type == 'snack'" icon="cookie" size="2x" transform="right-4 down-3" />
                        <font-awesome-icon v-if="meal.type == 'dinner'" icon="bread-slice" size="2x" transform="right-3 down-3" />
                    </a>
                </div>
                <div class="card-content">
                    <span class="card-title">{{ localeType(meal.type) }} - {{ formattedDate(meal.date) }}</span>
                    <p>{{ meal.description }}</p>
                    <p v-if="meal.description">{{ meal.description }}</p>
                        <p v-else-if="meal.products.length > 0">
                            <!-- create a line per meal.products entry with the title an the allergens -->
                            <p v-for="product in sortedProducts(meal.products)" :key="product.id">
                                {{ product.title }}
                            </p>
                    </p>
                    <span v-if="selectedResident">
                        <a v-if="!userIsEnrolledForMeal(meal)" @click.prevent="confirmParticipation(meal.id)" class="btn waves-effect waves-light green" style="width: 100%;">
                            <i class="material-icons left">rocket_launch</i>
                            Inschrijven!
                        </a>
                        <a  v-else @click.prevent="confirmUnsubscribe(meal.id)" class="btn waves-effect waves-light red" style="width: 100%;">
                            <i class="material-icons left">close</i>
                            Uitschrijven!
                        </a>
                    </span>
                </div>
            </div>
        </div>
    </div>
    <MealsSubscribeModal
      v-if="showMealsSubscribeModal"
      :meal="mealToSubscribe" :choicesToBeMade="choicesToBeMade" :user="selectedResident"
      @close="closeModal"
      @confirmed="participationIsConfirmed"
    />

    <MealsUnsubscribeModal
      v-if="showMealsUnsubscribeModal"
      :meal="mealToUnsubscribe" :user="selectedResident"
      @close="closeModalUnsubscribe"
      @confirmed="unsubscribeIsConfirmed"
    />
</div>
</template>

<script>
import store from '../../store';
import { format } from 'date-fns';
import { nl } from 'date-fns/locale';

import MealsSubscribeModal from './MealsSubscribeModal';
import MealsUnsubscribeModal from './MealsUnsubscribeModal';

export default {
    components: {
        MealsSubscribeModal,
        MealsUnsubscribeModal
    },
    methods: {
        participationIsConfirmed() {
            store.dispatch('fetchActiveResidentAccount').then(() => {
                this.meals = store.state.accountForActiveResident.meals
                this.mealToSubscribe = null
                this.choicesToBeMade = []
            })
        },
        unsubscribeIsConfirmed() {
            store.dispatch('fetchActiveResidentAccount').then(() => {
                this.meals = store.state.accountForActiveResident.meals
                this.mealToUnsubscribe = null
            })
        },
        sortedProducts(products) {
            return products.slice().sort((a, b) => (a.pivot.option_group > b.pivot.option_group) ? 1 : -1)
        },

        closeModal() {
            this.showMealsSubscribeModal = false
        },
        closeModalUnsubscribe() {
            this.showMealsUnsubscribeModal = false
        },
        confirmUnsubscribe(mealId)
        {
        this.mealToUnsubscribe = this.meals.find(meal => meal.id == mealId)
        this.showMealsUnsubscribeModal = true
        },
        confirmParticipation(mealId) {
            this.mealToSubscribe = this.mealsOpenForSubscription.find(meal => meal.id == mealId)
            // first check if there are choices to be made for this meal
            // For this we need to check 2 things:
            // 1. Does the meal have a product where the pivot table has a is_optional value of 1
            // 2. Does the meal have several products where the pivot table value of option_group is the same
            // On of these 2 conditions is true, we need to show a modal where the user can choose which product he wants to eat
            // If none of these conditions are true, we can just confirm the participation
            let meal = this.mealsOpenForSubscription.find(meal => meal.id == mealId)
            let optionalProducts = meal.products.filter(product => product.pivot.is_optional == 1)

            // check if there are products that have the same option_group set in the pivot table
            let optionGroups = []
            meal.products.forEach(product => {
                if(product.pivot.option_group != null)
                {
                optionGroups.push(product.pivot.option_group)
                }
            })
            let uniqueOptionGroups = [...new Set(optionGroups)]
            if(optionalProducts.length > 0 || uniqueOptionGroups.length > 0)
            {
                // we need to set the choicesToBeMade variable so we can show the choices in the modal
                // we want an array of products for this meal sorted by option_group integer
                // this array should hold all products that are optional and all products that have the same option_group
                // we can then loop over this array and show the products grouped by option_group
                let productsWithChoices = []

                // add all products that have the same option_group. only the ones where the option_group occurs at least twice
                // this is because we don't want to show products that have a unique option_group
                // we want these products with the same option_group to be grouped together in a nested array
                uniqueOptionGroups.forEach(optionGroup => {
                let productsWithSameOptionGroup = meal.products.filter(product => product.pivot.option_group == optionGroup)
                if(productsWithSameOptionGroup.length > 1)
                {
                    productsWithChoices.push(productsWithSameOptionGroup)
                }
                })

                // now add all products that are optional
                // keep in mind that the productsWithChoices array can contain nested arrays
                // and we only want to add the optional products if the option_group of the product is not already in the array
                // so we will loop over the optional products and check if the option_group is already in the array
                // if it is not, we will add the product to the array
                optionalProducts.forEach(product => {
                let optionGroupAlreadyInArray = false
                productsWithChoices.forEach(productWithChoice => {
                    if(Array.isArray(productWithChoice))
                    {
                    if(productWithChoice[0].pivot.option_group == product.pivot.option_group)
                    {
                        optionGroupAlreadyInArray = true
                    }
                    }
                    else
                    {
                    if(productWithChoice.pivot.option_group == product.pivot.option_group)
                    {
                        optionGroupAlreadyInArray = true
                    }
                    }
                })
                if(!optionGroupAlreadyInArray)
                {
                    productsWithChoices.push(product)
                }
                })

                // now we need to sort the productsWithChoices array by option_group
                // keep in mind that the productsWithChoices array can contain nested arrays
                // so we need to check if the array is nested or not
                // if it is nested, we need to sort the nested array by option_group of the first product in that nested array
                // if it is not nested, we can just sort the array by option_group of the product
                productsWithChoices.sort((a, b) => {
                if(Array.isArray(a))
                {
                    // check if b is also an array
                    if(Array.isArray(b))
                    {
                    return a[0].pivot.option_group - b[0].pivot.option_group
                    }
                    else
                    {
                    return a[0].pivot.option_group - b.pivot.option_group
                    }
                }
                else
                {
                    // check if b is an array
                    if(Array.isArray(b))
                    {
                    return a.pivot.option_group - b[0].pivot.option_group
                    }
                    else
                    {
                    return a.pivot.option_group - b.pivot.option_group
                    }
                }
                })

                this.choicesToBeMade = productsWithChoices
            }

            this.showMealsSubscribeModal = true

        },
        userIsEnrolledForMeal(meal) {
            return meal.enrollments.some(enrollment => enrollment.resident_id == this.selectedResident.id)
        },
        mealImage(media) {
            return `${process.env.VUE_APP_SERVER_URL}/storage/${media.id}/${media.file_name}`
        },
        formattedDate(date) {
            return format(new Date(date), 'dd MMM', { locale: nl })
        },
        localeType(type) {
            if(type == 'breakfast')
            {
                return this.$t('meals.breakfast')
            }
            else if (type == 'lunch')
            {
                return this.$t('meals.lunch')
            }
            else if (type == 'dinner')
            {
                return this.$t('meals.dinner')
            }
            else if (type == 'snack')
            {
                return this.$t('meals.snack')
            }
        }
    },
    data() {
        return {
            residents: store.state.residents,
            selectedResident: null,
            autocomplete: null,
            choicesToBeMade: [],
            mealToSubscribe: null,
            showMealsSubscribeModal: false,
            mealToUnsubscribe: null,
            showMealsUnsubscribeModal: false,
        }
    },
    mounted() {
        this.autocomplete = M.Autocomplete.init(document.getElementById('autocomplete-input'), {
            data: Object.assign({}, ...this.autocompleteData),
            onAutocomplete: (txt) => {
                this.selectedResident = this.residents.find(resident => `${resident.lname} ${resident.fname}` == txt)
            }
        });
    },
    computed: {
        /* Create an array of all the residents with the name as key and the avatar as value. */
        autocompleteData() {
            return this.residents.map(resident => ({
                // create a key value pair for the autocomplete
                [`${resident.lname} ${resident.fname}`]: resident.media.length ? resident.media[0].original_url : null,
            }))
        },

        /* Create an array of all meals in the future, with track_enrollment set to true and ultimate_enrollment_date (datetime field) is not yet passed. */
        mealsOpenForSubscription() {
            return store.state.accountForActiveResident.meals.filter(meal => {
                if(meal.ultimate_enrollment_date !== null) {
                    return meal.track_enrollments && new Date(meal.ultimate_enrollment_date) > new Date()
                } else {
                    return meal.track_enrollments
                }
            })
        },
    }
}
</script>