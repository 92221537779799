<template>
    <header>
        <img :src="accountLogo()" class="fp-logo" v-if="logo" />
        <img src="../assets/family-portal-banner-logo.png" class="fp-logo" v-else />
    </header>
</template>

<script>

export default {
    data() {
        return {
            logo: this.$store.state.accountForActiveResident.media[0]
        }
    },
    methods: {
        logout() {
            this.$store.dispatch('logout')
            this.$router.push('/login')
        },
        accountLogo() {
            return `${process.env.VUE_APP_SERVER_URL}/storage/${this.logo.id}/${this.logo.file_name}`
        }
    },
    computed: {
        isLoggedIn() {
            return this.$store.getters.isLoggedIn
        },
    },
}
</script>

<style scoped>

header {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    position: fixed;
    top: 0px;
    left: 0px;
    height: 100px;
    right: 0px;
    overflow: hidden;
}

.fp-logo {
    height: 100%;
}

</style>
