<template>
    <div>
        <h2>... processing media ...</h2>
    </div>
</template>

<script>
export default {

    mounted() {

        navigator.serviceWorker.addEventListener('message', event => {
            this.$router.push({ name: 'before-upload', params: { media: event.data.media }})
            // let reader = new FileReader()
            // reader.readAsDataURL(event.data.media)
            // reader.onload = e => {
            //     // console.dir(e)
            //     this.$router.push({ name: 'before-upload', params: { media: e.target.result } })
            // }
        })

    }

}
</script>

<style>

</style>