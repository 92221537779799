<template>
  <div class="container">
    <div class="row" v-if="activities.length > 0">
      <div class="col s12 m6" v-for="activity in activities" :key="activity.id">
        <div class="card">
          <div class="card-image">
            <img :src="activity.thumbnail" v-if="activity.media.length > 0 && activity.thumbnail !== null">
            <img :src="activity.media[0].preview_url" v-else-if="activity.media.length > 0 && activity.media[0].preview_url !== null">
            <img class="activity-placeholder" src="../../assets/activity-placeholder.png" v-else>
            <a class="btn-floating halfway-fab waves-effect waves-light red">
              <font-awesome-icon icon="hiking" size="2x" transform="right-4 down-3" />
            </a>
          </div>
          <div class="card-content">
            <span class="card-title">{{ formattedDate(activity.date) }}</span>
            <p>{{ activity.title }}</p>
            <p>{{ activity.location }}</p>
            <span v-if="activity.track_enrollments">
              <a v-if="!userIsEnrolledInActivity(activity)" @click.prevent="confirmParticipation(activity.id)" class="btn waves-effect waves-light green" style="width: 100%;">
                  <i class="material-icons left">rocket_launch</i>
                  Deelnemen!
              </a>
              <span v-else>
                <br />
                <i>U bent ingeschreven voor deze activiteit.</i>
                <a @click.prevent="" class="btn waves-effect waves-light red" style="width: 100%;">
                    <i class="material-icons left">close</i>
                    Uitschrijven!
                </a>
              </span>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-else>
      {{ $t('activities.noActivities') }}
    </div>
    <!-- Modal Structure -->
    <div id="confirm-participation" class="modal bottom-sheet" style="margin-bottom: 60px !important;">
        <div class="modal-content">
            <h4>Bevestig uw deelname!</h4>
            <ul class="collection">
                <li class="collection-item modal-option" @click="closeModalConfirmParticipation">
                    <font-awesome-icon style="cursor: pointer;" icon="times" />
                    Nee toch maar niet
                </li>
                <li class="collection-item modal-option modal-option-confirm" @click="confirmedParticipation">
                    <font-awesome-icon style="cursor: pointer;" icon="check" />
                    Ik zal er zijn!
                </li>
            </ul>
        </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { format } from 'date-fns'
import swal from 'sweetalert'

import store from '../../store'

export default {
  components: {
  },
  name: "activities",
  data() {
    return {
      activities: store.state.accountForActiveResident.activities,
      modal: {},
      modalActivity: {},
      user: this.$store.state.user
    }
  },
  methods: {
    userIsEnrolledInActivity(activity)
    {
      return activity.enrollments.map(a => a.user.id).includes(this.user.id)
    },
    closeModalConfirmParticipation()
    {
      this.modalActivity = {}
      this.modal.close()
    },
    confirmedParticipation()
    {
        axios.get(`${process.env.VUE_APP_SERVER_URL}/api/activity/${this.modalActivity.id}/user/${this.user.id}`, {
            headers: {
                'Authorization': 'Bearer ' + store.state.auth.auth_token
            }
        })
        .then((res) => {
          // close the modal and show a sweet alert
          this.modal.close()
          swal('Bevestiging', 'U bent geregistreerd om deel te nemen aan deze activiteit', 'success')
        })
        .catch((err) => {
          swal('Fout', 'We kunnen u niet via deze weg inschrijven voor deze activiteit.', 'error')
        })
    },
    confirmParticipation(id) {
      this.modalActivity = this.activities.find(item => {
        return id == item.id
      })
      this.modal.open()
    },
    formattedDate(date) {
      return format(new Date(date), 'dd-MM')
    }
  },
  created() {
  },
  mounted() {
    // update account information when mounted so we have the latest information
    store.dispatch('fetchActiveResidentAccount')

    var elem = document.getElementById('confirm-participation')
    this.modal = M.Modal.init(elem)
  },
  computed: {
  }
};
</script>

<style scoped>
.activity-placeholder {
  margin: auto;
  padding: 10px;
  width: 200px !important;
  height: 200px;
}
</style>