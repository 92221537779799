<template>
    <div class="row">
        <form class="col s12">
            <div class="row" style="margin-bottom: 0;">
                <div class="input-field col s12" @click="setFocusTextArea()">
                    <i class="material-icons prefix">mode_edit</i>
                    <textarea v-model="caption" class="materialize-textarea" ref="caption"></textarea>
                    <label for="textarea1">Typ hier uw bericht voor {{ resident.fname }} {{ resident.lname }}</label>
                </div>
            </div>
            <div class="row" style="margin-bottom: 0;">
                <div class="input-field col s12">
                    <select class="browser-default" v-model="privacy">
                        <option value="1">Enkel zichtbaar voor {{ resident.fname }} {{ resident.lname }}</option>
                        <option value="2">Zichtbaar voor familie/vrienden</option>
                    </select>
                </div>
            </div>
            <div class="row">
                <div style="display: flex; align-items: center; justify-content: center;">
                    <a @click.prevent="cancelUpload" class="btn waves-effect waves-light red" style="margin-right: 10px">
                        <i class="material-icons left">cancel</i>
                        Cancel
                    </a>
                    <a @click.prevent="sendMessage" class="btn waves-effect waves-light green">
                        <i class="material-icons left">check</i>
                        Verzenden
                    </a>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import swal from 'sweetalert'
import store from '../store'

export default {
    data () {
        return {
            resident: {},
            caption: '',
            privacy: 1,
        }
    },
    mounted () {
        this.resident = store.state.residents[store.state.activeResident]
    },
    methods: {
        setFocusTextArea() {
            this.$refs.caption.focus()
        },
        cancelUpload() {
            this.$router.push('/')
        },
        sendMessage() {
            let form = new FormData()
            form.append('resident_id', this.resident.id)
            form.append('caption', this.caption)
            form.append('privacy', this.privacy)
            form.append('media_type', 3)

            axios.post(process.env.VUE_APP_SERVER_URL + '/api/create-new-post', form, {
                headers: {
                    'Authorization': 'Bearer ' + store.state.auth.auth_token
                }
            }).then((response) => {
                swal('Helemaal klaar!', 'Uw bericht werd verzonden!', 'success').then((value) => {
                    store.dispatch('fetchActiveResidentPosts').then(() => this.$router.push('/'))
                })
            }).catch((error) => {
                // show an error message
                console.error(error)
                swal('Error!', 'Uw bericht kon niet opgeladen worden door een fout op de server!', 'error')
            })
        }
    },
    destroyed () {
    }
    
}
</script>

<style scoped>

</style>