const vue_fb = {} 
vue_fb.install = function install(Vue, options) {
    (function(d, s, id){
        var js, fjs = d.getElementsByTagName(s)[0]
        if (d.getElementById(id)) {return}
        js = d.createElement(s)
        js.id = id
        js.src = "//connect.facebook.net/en_US/sdk.js"
        fjs.parentNode.insertBefore(js, fjs)
        // console.log('setting fb sdk')
    }(document, 'script', 'facebook-jssdk'))

    window.fbAsyncInit = function onSDKInit() {
        FB.init(options)
        FB.AppEvents.logPageView()
        Vue.FB = FB
        window.fb_sdk_ready = true
    }
    Vue.FB = undefined
}

import Vue from 'vue'
import store from './store'
import router from './router'
import AppWrapper from './AppWrapper.vue'
import normalizecss from 'normalize.css'
import materialize from 'materialize-css'

window.axios = require('axios')

window.axios.interceptors.response.use((res) => {
  return res
}, async (err) => {
  if (err.response.status === 401) {
    store.dispatch('logout')
    router.push('/login')
  }
})

import OnnoffToggle from 'vue-onoff-toggle'
Vue.use(OnnoffToggle)

import VueI18n from 'vue-i18n'
Vue.use(VueI18n)

const messages = {
  en: {
    general: {
      installApp: 'install app',
      updateApp: 'new update - click here'
    },
    login: {
      greeting: 'Hello',
      instructions: 'Choose a method to login',
      google: 'Continue with Google',
      facebook: 'Continue with Facebook',
      email: 'Continue with e-mail',
      emailLabel: 'E-mail',
      password: 'Password',
      registerText: 'No account yet? Register here'
    },
    register: {
      fname: 'First Name',
      lname: 'Last Name',
      email: 'E-mail',
      password: 'Password',
      register: 'Register',
      backToLogin: 'Back to login page'
    },
    linkResident: {
      greeting: 'Hello {name}',
      instructions: 'Enter here de unique code for the resident. You can get this code from the home or on the TV of the elder.',
      cancel: 'Cancel',
    },
    newsFeed: {
      preparingMedia: 'Please wait while we prepare your post for online use.',
      noPosts: 'Add the first posts for this resident by clicking on the buttons above',
      titleDelete: 'Are you sure?',
      cancelDelete: 'No keep it!',
      confirmDelete: 'Yes get rid of it' 
    },
    beforeUpload: {
      caption: 'Caption Text (optional)',
      visibleFamily: 'Visible for family/friends',
      visibleResident: 'Visible for {name}',
      cancel: 'Cancel',
      upload: 'Upload',
      done: 'Ready',
      doneCaption: `You're post was uploaded!`,
      error: 'Error',
      errorCaption: `You're post could not be uploaded. There is an error on the server`
    },
    meals: {
      breakfast: 'breakfast',
      lunch: 'lunch',
      snack: 'snack',
      dinner: 'dinner',
      noMeals: 'There are currently no meals uploaded.',
    },
    activities: {
      noActivities: 'There are currently no activities uploaded.'
    },
    news: {
      noNews: 'There are currently no news items uploaded.',
    },
    settings: {
      addResident: 'Add a resident',
      addResidentDesc: `Enter the resident's unique code. You can find this on the resident's television set or request it at the care home.`,
      general: 'General',
      defaultShare: 'Share new posts with family or friends.',
      defaultShareDesc: `If you enable this option new messages, photos or videos will be shared with everyone linked to the resident for whom you are uploading messages. If you disable this you will only share it with the resident you are linked to.'`,
      changeNickname: `Wijzig bijnaam`,
      changeNicknameDesc: `Kies zelf een persoonlijke bijnaam voor de resident. Deze bijnaam wordt niet gedeeld met andere familieleden. Die kunnen steeds zelf een eigen bijnaam kiezen.`,
      notifications: `Notifications`,
      notificationsPush: `Push notifications`,
      notificationsPushDesc: `With push notifications you will receive an automatic notification on your phone when an action has occured, such as a message or a photo from a family member or friend. Push notifications are currently not supported on iOS for web apps`,
      notificationsPushBlockedDesc: `Push notifications are blocked in your site settings. Go to the website in your browser and select 'Site Settings'. There you can allow 'Notifications'.`,
      notificationsPushNotSupportedDesc: `Your system does not support Push Notifications. iOS (Apple) does not support this technology for web apps. `,
      notificationsEmail: `Email notifications`,
      notificationsEmailDesc: `With email notifications you will receive an automatic notification by email when an action has occured, such as a message or a photo from a family member or friend.`,
      privacy: 'Privacy Policy',
      privacyPolicy: `

        <p>The Family Portal app is a product distributed by Concept-IT, located at Gitsstraat 41, 8820 Torhout.</p>
        <p>Concept-IT  is responsible for processing the personal data as described in this privacy declaration.</p>

        <h6>Contact info:</h6>
        <p>
          https://familyportal.be<br />
          Gitsstraat 41, 8820 Torhout<br />
          +32 491 48 54 19<br />
        </p>

        <h6>Personal data we process</h6>
        <p>Concept-IT  processes your personal data because you use our services and / or because you provide this information to us yourself. 
          Below you will find an overview of the personal data we process:</p>
        <ul>
          <li>- Full name</li>
          <li>- Email address</li>
          <li>- Other personal date you actively provide to us, e.g. by creating a profile on this website, by mail or by telephone</li>
          <li>- Location data</li>
          <li>- Internet browser and device type</li>
        </ul>

        <h6>Special and / or sensitive personal data that we process</h6>
        <p>Concept-IT  processes the following special and / or sensitive personal data from you:
        <ul>
          <li>- RAS (since we save your profile picture from Facebook or Google this is also considered as RAS)</li>
        </ul>
        <h6> For what purpose and on what basis we process personal data</h6>
        <p> Concept-IT processes your personal data for the following purposes: </p>
        <ul>
          <li> - To send our newsletter and / or advertising brochures </li>
          <li> - To inform you about changes to our services and products </li>
          <li> - To offer you the opportunity to create an account </li>
          <li> - To provide you with goods and services </li>
        </ul>
        
        <h6> Automated Decision Making </h6>
        <p> Concept-IT does not make decisions based on automated processing on matters that can have (significant) consequences for people.
          These are decisions taken by computer programs or systems, without involving a person (for example, a Concept-IT employee). </p>

        <h6> How long we keep personal data </h6>
        <p> Concept-IT does not store your personal data for longer than is strictly necessary to achieve the purposes for which your data is collected.
          We use the following retention periods for the following personal data (categories): </p>
        <ul>
          <li> Personal data> As long as your account is active. After deleting your account, the data will be deleted immediately. </li>
          <li> Resident data> As long as the resident resides in the care home that is using the application. The data will be deleted upon leaving the care home or the passing of the resident. </li>
        </ul>

        <h6> Sharing personal data with third parties </h6>
          <p> Concept-IT will not sell your information to third parties and will only provide it if this is in line with the execution of our agreement with you or to
          comply with a legal obligation. With companies that process your data on our behalf we have a processor agreement to vouchsafe the same
          level of security and confidentiality of your data. Concept-IT remains responsible for these processing operations. </p>

        <h6> Cookies, or similar techniques, that we use </h6>
        <p> Concept-IT only uses technical and functional cookies as well as analytical cookies that do not infringe on your privacy. A cookie is a small text file that
          is stored on your computer, tablet or smartphone when you first visit this website.. The cookies we use are necessary for the technical
          operation of the website and your ease of use. They ensure that the website works properly and, for example, remember your preferred settings. We can also
          optimize our website with this. You can opt out of cookies by setting your internet browser so that it no longer stores cookies. In addition, you can also
          delete all information previously saved via your browser settings. </p>
	
        <h6> View, modify or delete data </h6>
        <p> You have the right to view, correct or delete your personal data. You can do this yourself via the personal settings of your account.
          You also have the right to withdraw your consent to the data processing or to object to the processing of your personal data by our company and
          you have the right to data portability. This means that you can submit a request to us for the personal data that we have about you in a computer file
          to you or another organization mentioned by you. In case you want to use your right to object and / or right to data portability or if you have other
          questions / comments about the data processing, please send a specified request to dante@familyportal.be. To ensure that the request for access has been made by you,
          we ask you to enclose a copy of your ID with the request. In this copy black out your passport photo, MRZ (machine readable zone, the strip with numbers at the bottom of the passport),
          passport number and national register number (RR). This is to protect your privacy. Concept-IT will respond to your request as soon as possible, but in any case within four weeks.
          Concept-IT would also like to point out that you have the opportunity to file a complaint with the national supervisory authority. </p>        

        <h6> How we secure personal data </h6>
        <p> Concept-IT takes the protection of your data seriously and takes appropriate measures to prevent misuse, loss, unauthorized access, unwanted disclosure and unauthorized changes.
          If you feel that your data is not properly secured or there are any indications of abuse, please contact our customer service or dante@familyportal.be </p>

      `,
      logout: 'Logout',
      version: 'Version'

    }

  },
  fr: {
    general: {
      installApp: `installer l'app`,
      updateApp: 'nouvelle mise à jour - cliquer ici'
    },
    login: {
      greeting: 'Bonjour',
      instructions: 'Choisissez une méthode de connexion',
      google: 'Continuer avec Google',
      facebook: 'Continuer avec Facebook',
      email: 'Continuer avec e-mail',
      emailLabel: 'E-mail',
      password: 'Mot de passe',
      registerText: 'Pas encore de compte? Inscrivez-vous ici'
    },
    register: {
      fname: 'Prénom',
      lname: 'Nom',
      email: 'E-mail',
      password: 'Mot de passe',
      register: 'Enregistrer',
      backToLogin: 'Retour à la page de connexion'
    },
    linkResident: {
      greeting: 'Bonjour {name}',
      instructions: 'Entrez ici le code unique du résident. Vous pouvez en faire la demande auprès du Home ou sur le téléviseur du résident.',
      cancel: 'Annuler',
    },
    newsFeed: {
      preparingMedia: 'Veuillez patienter pendant que nous adaptons votre message/post pour une utilisation en ligne',
      noPosts: `Ajoutez le premier message/post en cliquant sur l'un des boutons ci-dessus`,
      titleDelete: 'Êtes-vous sûr?',
      cancelDelete: 'Finalement non!',
      confirmDelete: 'Oui, vous pouvez supprimer' 
    },
    beforeUpload: {
      caption: 'Oui, vous pouvez supprimer',
      visibleFamily: 'Visible par la famille / les amis',
      visibleResident: 'Visible par {name}',
      cancel: 'Annuler',
      upload: 'Charger',
      done: 'Tout à fait terminé!',
      doneCaption: `Votre message a été téléchargé!`,
      error: 'Erreur',
      errorCaption: `Votre message n'a pas pu être téléchargée en raison d'une erreur sur le serveur!`
    },
    meals: { 
      breakfast: 'petit déjeuner',
      lunch: 'déjeuner',
      snack: 'goûter',
      dinner: 'souper',
      noMeals: 'pas de repas planifiés',
    },
    activities: {
      noActivities: `pas d'activités planifiées`
    },
    news: {
      noNews: 'aucune actualités au programme'
    },
    settings: {
      addResident: 'ajoutez un résident',
      addResidentDesc: 'ajoutez le code du résident. Vous pouvez obtenir ce code auprès de la maison de repos ou sur la tv du résident',
      general: 'général', 
      defaultShare: 'partagez de nouveaux messages avec des amis/ la famille.',
      defaultShareDesc: `Si vous activez cette option, toutes les personnes liées au résident seront en mesure de voir tout nouveau message, photo et/ou vidéo. Si vous désactivez l’option, votre message ne sera partagé qu’avec le résident`,
      changeNickname: `Wijzig bijnaam`,
      changeNicknameDesc: `Kies zelf een persoonlijke bijnaam voor de resident. Deze bijnaam wordt niet gedeeld met andere familieleden. Die kunnen steeds zelf een eigen bijnaam kiezen.`,
      notifications: 'notifications',
      notificationsPush: 'notification push',
      notificationsPushDesc: `avec la fonction «  notifications push », une notification est envoyée sur votre téléphone, à chaque nouveau message, photo ou vidéo posté. Les « notifications push » ne fonctionnent pas sur IOS.`,
      notificationsPushBlockedDesc: `Les notifications push sont bloquées dans les paramètres de votre site. Accédez au site Web dans votre navigateur et sélectionnez «Paramètres du site». Là, vous pouvez autoriser les «notifications» .`,
      notificationsPushNotSupportedDesc: `Votre système ne prend pas en charge les notifications push. iOS (Apple) ne prend pas en charge cette technologie pour les applications Web.`,
      notificationsEmail: 'notifications e-mails',
      notificationsEmailDesc: `avec la fonction « notifications par mail », vous recevez un e-mail à chaque fois que quelqu'un poste quelque chose de nouveau.`,
      privacy: 'politique de confidentialité',
      privacyPolicy: `
        <p>Le Portal Family app est un produit distribuée par Concept-IT, dont le siège est sis à l’adresse suivante : Gitsstraat 41, 8820 Torhout</p>
        <p>Concept-IT est responsable du traitement des données personnelles comme indiqué dans cette déclaration de confidentialité.</p>: 
        <h6>Détails de contact:</h6>
        <p>
          https://familyportal.be<br />
          Gitsstraat 41, 8820 Torhout<br />
          +32 471 48 54 19<br />
        </p>
     
	<h6> Données personnelles que nous traitons </h6>
        <p> Concept-IT traite vos données personnelles parce que vous utilisez nos services et / ou parce que vous nous fournissez vous-même ces informations.
          Vous trouverez ci-dessous un aperçu des données personnelles que nous traitons: </p>
        <ul>
          <li> - Prénom et nom </li>
          <li> - Adresse e-mail </li>
          <li> - Autres données personnelles que vous fournissez activement, par exemple en créant un profil sur ce site Web, par correspondance et par téléphone </li>
          <li> - Informations de localisation </li>
          <li> - Navigateur Internet et type d'appareil </li>
        </ul>
        <h6> Données personnelles spéciales et / ou sensibles que nous traitons </h6>
        <p> Concept-IT traite les données personnelles spéciales et / ou sensibles suivantes vous concernant:
        <ul>
          <li> - Politique accès distance (étant donné que nous enregistrons votre photo de profil sur Facebook ou Google, cela est également considéré comme « Politique accès distance ») </li>
        </ul>
        
        <h6> Dans quel but et sur quelle base traitons-nous les données personnelles </h6>
        <p> Concept-IT traite vos données personnelles aux fins suivantes: </p>
        <ul>
          <li> - Envoi de notre newsletter et / ou brochure publicitaire </li>
          <li> - Pour vous informer des modifications apportées à nos services et produits </li>
          <li> - Pour vous offrir la possibilité de créer un compte </li>
          <li> - Pour vous livrer des biens et des services </li>
        </ul>
	<h6> Prise de décision automatisée </h6>
        <p> Concept-IT ne prend pas de décisions basées sur un traitement automatisé sur des sujets pouvant avoir des conséquences (importantes) pour les personnes.
          Ce sont des décisions prises par des programmes ou des systèmes informatiques, sans impliquer une personne (par exemple, un employé de Concept-IT). </p>
        <h6> Combien de temps nous conservons les données personnelles </h6>
        <p> Concept-IT ne stocke pas vos données personnelles plus longtemps que ce qui est strictement nécessaire pour atteindre les objectifs pour lesquels vos données sont collectées.
          Nous utilisons les périodes de conservation suivantes pour les (catégories) de données personnelles suivantes: </p>
        <ul>
          <li> Données personnelles> Tant que votre compte est actif. Après la suppression de votre compte, les données sont supprimées immédiatement. </li>
          <li> Données du résident> Tant que le résident réside dans l'établissement et utilise l'application. Les données sont supprimées à la sortie de l'établissement ou lors de son décès. </li>
        </ul>
        <h6> Partage de données personnelles avec des tiers </h6>
        <p> Concept-IT ne vendra pas vos informations à des tiers et ne les fournira que si cela est nécessaire pour l'exécution de notre accord ou pour
          respecter une obligation légale. Nous concluons un accord de traitement avec des entreprises qui traitent vos données en notre nom pour garantir le même niveau de sécurité et de confidentialité de vos données. Concept-IT reste responsable de ces opérations de traitement. </p>
        <h6> Cookies ou techniques similaires que nous utilisons </h6>
        <p> Concept-IT n'utilise que des cookies techniques et fonctionnels, ainsi que des cookies analytiques qui ne violent pas votre vie privée. Un cookie est un petit fichier texte qui
          lors de votre première visite sur ce site Web est stocké sur votre ordinateur, tablette ou smartphone. Les cookies que nous utilisons sont nécessaires pour le fonctionnement du site Web et votre facilité d'utilisation. Les cookies s'assurent que le site Web fonctionne correctement et, par exemple, mémorisent vos paramètres préférés. Nous pouvons ainsi optimiser notre site Web avec cela. Vous pouvez désactiver les cookies en paramétrant votre navigateur Internet pour qu'il ne stocke plus de cookies. De plus, vous pouvez également
          supprimer toutes les informations précédemment enregistrées via les paramètres de votre navigateur. </p>
	<h6> Afficher, modifier ou supprimer des données </h6>
        <p> Vous avez le droit de consulter, de corriger ou de supprimer vos données personnelles. Vous pouvez le faire vous-même via les paramètres personnels de votre compte.
          De plus, vous avez le droit de retirer votre consentement au traitement des données ou de vous opposer au traitement de vos données personnelles par notre société et
          vous avez le droit à la portabilité des données. Cela signifie que vous pouvez nous soumettre une demande afin que les données personnelles que nous disposons sur vous dans un fichier informatique
          ou celles transmises à une autre organisation avec votre consentement, vous soient restituées. Voulez-vous utiliser votre droit d'opposition et / ou droit à la portabilité des données ou avez-vous d'autres questions / commentaires sur le traitement des données ? Si oui, envoyez une demande spécifique à dante@familyportal.be. Pour nous assurer que c’est bien vous qui êtes à l’origine de la demande d'accès nous vous demandons de joindre une copie de votre pièce d'identité à la demande. Votre photo d'identité doit faire partie de la copie, le MRZ (Machine-Readable passport, zone lisible par une machine, à savoir la bande numérotée en bas du passeport), le numéro de passeport et votre numéro de registre national (RR) en noir. C'est pour protéger votre vie privée. Concept-IT répondra à votre demande dans les plus brefs délais et au plus tard dans les quatre semaines.
          Concept-IT tient également à souligner que vous avez la possibilité de déposer une plainte auprès de l'autorité de surveillance nationale. </p>
        <h6> Comment sécurisons nous les données personnelles ? </h6>
        <p> Concept-IT prend la protection de vos données au sérieux et prend les mesures appropriées pour éviter toute utilisation abusive, perte, accès non autorisé, divulgation indésirable et modification non autorisée ;
          Si vous pensez que vos données ne sont pas correctement sécurisées ou s'il y a des signes d'abus, veuillez contacter notre service client via dante@familyportal.be </p>
      `,
      logout: 'déconnexion',
      version: 'version'        
    }
  },
  nl: {
    general: {
      installApp: 'installeer app',
      updateApp: 'nieuwe update - klik hier'
    },
    login: {
      greeting: 'Hallo',
      instructions: 'Maak een keuze om verder te gaan',
      google: 'Ga door met Google',
      facebook: 'Ga door met Facebook',
      email: 'Verder met e-mail',
      emailLabel: 'E-mail',
      password: 'Wachtwoord',
      registerText: 'Nog geen account? Registreer hier.'
    },
    register: {
      fname: 'Voornaam',
      lname: 'Familienaam',
      email: 'E-mail',
      password: 'Wachtwoord',
      register: 'Registreer',
      backToLogin: 'Terug naar de login pagina'
    },
    linkResident: {
      greeting: 'Hallo {name}',
      instructions: 'Geef hier de unieke code van de resident. U kan deze opvragen bij het WZC of op de TV van de bewoner.',
      cancel: 'Annuleren',
    },
    newsFeed: {
      preparingMedia: 'Gelieve even te wachten terwijl we uw post geschikt maken voor online gebruik',
      noPosts: 'Voeg de eerste post toe door op één van bovenstaande knoppen te klikken',
      titleDelete: 'Bent u zeker?',
      cancelDelete: 'Nee toch niet!',
      confirmDelete: 'Ja doe maar weg!' 
    },
    beforeUpload: {
      caption: 'Bijhorende tekst (optioneel)',
      visibleFamily: 'Zichtbaar voor familie/vrienden',
      visibleResident: 'Zichtbaar voor {name}',
      cancel: 'Annuleren',
      upload: 'Opladen',
      done: 'Helemaal Klaar!',
      doneCaption: `Uw post werd opgeladen!`,
      error: 'Error',
      errorCaption: 'Uw foto kon niet opgeladen worden door een fout op de server!'
    },
    meals: {
      breakfast: 'ontbijt',
      lunch: 'middagmaal',
      snack: 'tussendoortje',
      dinner: 'avondmaal',
      noMeals: 'Er zijn geen maaltijden opgeladen.',
    },
    activities: {
      noActivities: 'Er zijn geen activiteiten opgeladen.'
    },
    news: {
      noNews: 'Er zijn momenteel geen nieuwsberichten opgeladen.',
    },
    settings: {
      addResident: 'Bewoner toevoegen',
      addResidentDesc: 'Geef hier de unieke code van de resident. U kan deze opvragen bij het WZC of op de TV van de bewoner',
      general: 'Algemeen',
      defaultShare: 'Deel nieuwe posts met familie/vrienden.',
      defaultShareDesc: `Als u deze optie inschakelt zullen nieuwe berichten, foto's en/of video's gedeeld worden met iedereen gelinkt aan de persoon waarvoor u het bericht oplaadt. Bij het uitschakelen deelt u uw bericht enkel met de persoon waar u aan gelinkt bent.'`,
      changeNickname: `Wijzig bijnaam`,
      changeNicknameDesc: `Kies zelf een persoonlijke bijnaam voor de resident. Deze bijnaam wordt niet gedeeld met andere familieleden. Die kunnen steeds zelf een eigen bijnaam kiezen.`,
      notifications: `Notificaties`,
      notificationsPush: `Push Meldingen`,
      notificationsPushDesc: `Met Push meldingen worden er bij nieuwe acties (nieuwsbericht, foto van familie / vrienden) een melding gestuurd naar uw telefoon. Push meldingen werken niet op iOS voor web apps.`,
      notificationsPushBlockedDesc: `Push meldingen zijn geblokkeerd in uw site instellingen. Ga in uw browser naar de website en kies voor 'Site Instellingen'. Daar kan u 'Meldingen' of 'Notifications' toelaten.`,
      notificationsPushNotSupportedDesc: `Uw systeem ondersteund geen Push Meldingen. iOS (Apple) ondersteund deze technologie niet voor web apps.`,
      notificationsEmail: `E-mail meldingen`,
      notificationsEmailDesc: `Met E-mail meldingen worden er bij nieuwe acties (nieuwsbericht, foto van familie / vrienden) een melding gestuurd naar uw mailbox.`,
      privacy: 'Privacy Policy',
      privacyPolicy: `

        <p>De Family Portal app is een product verdeeld door Concept-IT , gevestigd te Gitsstraat 41, 8820 Torhout.</p>
        <p>Concept-IT  is verantwoordelijk voor de verwerking van persoonsgegevens zoals weergegeven in deze privacyverklaring.</p>

        <h6>Contactgegevens:</h6>
        <p>
          https://familyportal.be<br />
          Gitsstraat 41, 8820 Torhout<br />
          +32 471 48 54 19<br />
        </p>

        <h6>Persoonsgegevens die wij verwerken</h6>
        <p>Concept-IT  verwerkt je persoonsgegevens doordat je gebruik maakt van onze diensten en/of omdat je deze gegevens zelf aan ons verstrekt. 
          Hieronder vind je een overzicht van de persoonsgegevens die wij verwerken:</p>
        <ul>
          <li>- Voor- en achternaam</li>
          <li>- E-mailadres</li>
          <li>- Overige persoonsgegevens die je actief verstrekt bijvoorbeeld door een profiel op deze website aan te maken, in correspondentie en telefonisch</li>
          <li>- Locatiegegevens</li>
          <li>- Internetbrowser en apparaat type</li>
        </ul>

        <h6>Bijzondere en/of gevoelige persoonsgegevens die wij verwerken</h6>
        <p>Concept-IT  verwerkt de volgende bijzondere en/of gevoelige persoonsgegevens van jou:
        <ul>
          <li>- ras (aangezien we uw profielfoto opslaan uit Facebook of Google wordt dit ook beschouwd als ras)</li>
        </ul>
        
        <h6>Met welk doel en op basis van welke grondslag wij persoonsgegevens verwerken</h6>
        <p>Concept-IT  verwerkt jouw persoonsgegevens voor de volgende doelen:</p>
        <ul>
          <li>- Verzenden van onze nieuwsbrief en/of reclamefolder</li>
          <li>- Je te informeren over wijzigingen van onze diensten en producten</li>
          <li>- Je de mogelijkheid te bieden een account aan te maken</li>
          <li>- Om goederen en diensten bij je af te leveren</li>
        </ul>
        
        <h6>Geautomatiseerde besluitvorming</h6>
        <p>Concept-IT  neemt niet op basis van geautomatiseerde verwerkingen besluiten over zaken die (aanzienlijke) gevolgen kunnen hebben voor personen. 
          Het gaat hier om besluiten die worden genomen door computerprogramma's of -systemen, zonder dat daar een mens (bijvoorbeeld een medewerker van Concept-IT ) tussen zit.</p> 

        <h6>Hoe lang we persoonsgegevens bewaren</h6>
        <p>Concept-IT  bewaart je persoonsgegevens niet langer dan strikt nodig is om de doelen te realiseren waarvoor je gegevens worden verzameld. 
          Wij hanteren de volgende bewaartermijnen voor de volgende (categorieën) van persoonsgegevens: </p>
        <ul>
          <li>Persoonsgegevens > Zolang uw account actief is. Na het verwijderen van uw account worden de gegevens meteen verwijderd.</li>
          <li>Residentgegevens > Zolang de resident in de voorziening verblijft die gebruikt maakt van de applicatie. Bij het verlaten van de voorziening of bij overlijden worden de gegevens gewist.</li>
        </ul>

        <h6>Delen van persoonsgegevens met derden</h6>
        <p>Concept-IT  verkoopt jouw gegevens niet aan derden en zal deze uitsluitend verstrekken indien dit nodig is voor de uitvoering van onze overeenkomst met jou of om te 
          voldoen aan een wettelijke verplichting. Met bedrijven die jouw gegevens verwerken in onze opdracht, sluiten wij een bewerkersovereenkomst om te zorgen voor eenzelfde 
          niveau van beveiliging en vertrouwelijkheid van jouw gegevens. Concept-IT  blijft verantwoordelijk voor deze verwerkingen.</p>

        <h6>Cookies, of vergelijkbare technieken, die wij gebruiken</h6>
        <p>Concept-IT  gebruikt alleen technische en functionele cookies. En analytische cookies die geen inbreuk maken op je privacy. Een cookie is een klein tekstbestand dat 
          bij het eerste bezoek aan deze website wordt opgeslagen op jouw computer, tablet of smartphone. De cookies die wij gebruiken zijn noodzakelijk voor de technische 
          werking van de website en jouw gebruiksgemak. Ze zorgen ervoor dat de website naar behoren werkt en onthouden bijvoorbeeld jouw voorkeursinstellingen. Ook kunnen wij 
          hiermee onze website optimaliseren. Je kunt je afmelden voor cookies door je internetbrowser zo in te stellen dat deze geen cookies meer opslaat. Daarnaast kun je ook 
          alle informatie die eerder is opgeslagen via de instellingen van je browser verwijderen.</p>

        <h6>Gegevens inzien, aanpassen of verwijderen</h6>
        <p>Je hebt het recht om je persoonsgegevens in te zien, te corrigeren of te verwijderen. Dit kun je zelf doen via de persoonlijke instellingen van jouw account. Daarnaast 
          heb je het recht om je eventuele toestemming voor de gegevensverwerking in te trekken of bezwaar te maken tegen de verwerking van jouw persoonsgegevens door ons bedrijf en 
          heb je het recht op gegevensoverdraagbaarheid. Dat betekent dat je bij ons een verzoek kan indienen om de persoonsgegevens die wij van jou beschikken in een computerbestand 
          naar jou of een ander, door jou genoemde organisatie, te sturen. Wil je gebruik maken van je recht op bezwaar en/of recht op gegevensoverdraagbaarheid of heb je andere 
          vragen/opmerkingen over de gegevensverwerking, stuur dan een gespecificeerd verzoek naar dante@familyportal.be. Om er zeker van te zijn dat het verzoek tot inzage door jou is gedaan, 
          vragen wij jou een kopie van je identiteitsbewijs bij het verzoek mee te sturen. Maak in deze kopie je pasfoto, MRZ (machine readable zone, de strook met nummers onderaan het paspoort), 
          paspoortnummer en rijksregisternummer (RR) zwart. Dit ter bescherming van je privacy. Concept-IT  zal zo snel mogelijk, maar in ieder geval binnen vier weken, op jouw verzoek reageren. 
          Concept-IT  wil je er tevens op wijzen dat je de mogelijkheid hebt om een klacht in te dienen bij de nationale toezichthouder.</p>

        <h6>Hoe wij persoonsgegevens beveiligen</h6>
        <p>Concept-IT  neemt de bescherming van jouw gegevens serieus en neemt passende maatregelen om misbruik, verlies, onbevoegde toegang, ongewenste openbaarmaking en ongeoorloofde wijziging 
          tegen te gaan. Als jij het idee hebt dat jouw gegevens toch niet goed beveiligd zijn of er aanwijzingen zijn van misbruik, neem dan contact op met onze klantenservice of via dante@familyportal.be</p>

      `,
      logout: 'Uitloggen',
      version: 'Versie'

    }

  }
}

import getBrowserLocale from './helpers/get-browser-locale'
import { supportedLocalesInclude } from './helpers/supported-locales'

function getStartingLocale() {
  const browserLocale = getBrowserLocale({ countryCodeOnly: true })

  if (supportedLocalesInclude(browserLocale)) {
    return browserLocale
  } else {
    return 'nl'
  }
}

const i18n = new VueI18n({
  locale: getStartingLocale(),
  messages
})

Vue.use(vue_fb, {
  appId: '2223366687891832',
  autoLogAppEvents: true,
  xfbml: false,
  version: 'v13.0'
})

import LoadScript from 'vue-plugin-load-script';
Vue.use(LoadScript)

import { library } from '@fortawesome/fontawesome-svg-core'
import { faFacebook, faGoogle } from '@fortawesome/free-brands-svg-icons'
import { faCoffee, faMugHot, faBreadSlice, faHiking, faClock, faTrash, faTimes, faUtensils, faCookie, faAddressCard, faSave} from '@fortawesome/free-solid-svg-icons'
// import footer icons
import { faHome as fasHome, faUtensils as fasUtensils, faWalking as fasWalking, faNewspaper as fasNewspaper, faCog as fasCog, faCheck as fasCheck} from '@fortawesome/pro-solid-svg-icons'
import { faHome as falHome, faUtensils as falUtensils, faWalking as falWalking, faNewspaper as falNewspaper, faCog as falCog, faCheck as falCheck} from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

// import './registerServiceWorker'
import '../node_modules/materialize-css/sass/materialize.scss'
import '../node_modules/materialize-social/materialize-social.css'

// add footer icons to library
library.add(fasHome, falHome, fasUtensils, falUtensils, fasWalking, falWalking, fasNewspaper, falNewspaper, fasCog, falCog, faSave)
library.add(faFacebook, faGoogle, faCoffee, faMugHot, faBreadSlice, faHiking, faClock, faTrash, faTimes, faCookie, faAddressCard, fasCheck)
Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.config.productionTip = false

new Vue({
  i18n,
  router,
  store,
  render: h => h(AppWrapper),
  async beforeCreate() {
    await this.$store.commit('INITIALISE_STORE')
  }
}).$mount('#app')

// Subscribe to store updates
// Lets us store the store in Local Storage. Whenever the user refreshes the website we can retrieve it back.
store.subscribe((mutation, state) => {
  // Store the state object as a JSON string
  localStorage.setItem('store', JSON.stringify(state))
})