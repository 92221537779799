<template>
  <div v-if="updateExists" @click="refreshApp">
      <a class="waves-effect waves-light btn-small pink darken-2 install-app"><i class="material-icons left">get_app</i> {{ $t('general.updateApp') }}</a>
  </div>
</template>

<script>
export default {
  data() {
    return {
      refreshing: false,
      registration: null,
      updateExists: false
    }
  },
  async mounted() {

    if('serviceWorker' in navigator) {
      this.registration = await navigator.serviceWorker.register('./service-worker.js')

      // console.log(this.registration.waiting)

      if(this.registration.waiting) {
        this.updateExists = true
      }

      this.registration.addEventListener('updatefound', () => {
        if(this.registration.installing) {
          this.registration.installing.addEventListener('statechange', () => {
            if(this.registration.waiting){
              // if there's an existing controller, show the prompt
              if(navigator.serviceWorker.controller) {
                this.updateExists = true
              }
            }
          })
        }
      })

      let refreshing = false

      navigator.serviceWorker.addEventListener('controllerchange', () => {
        if(!refreshing) {
          window.location.reload()
          refreshing = true
        }
      })
    }

  },
  created() {


    // document.addEventListener(
    //   'swUpdated', this.showRefreshUI, { once: true }
    // )

    // navigator.serviceWorker.addEventListener(
    //   'controllerchange', () => {
    //     if (this.refreshing) return
    //     this.refreshing = true
    //     window.location.reload()
    //   }
    // )
  },
  methods: {
    // showRefreshUI (e) {
    //   this.registration = e.detail
    //   this.updateExists = true
    // },
    refreshApp() {
      this.updateExists = false

      // if(!this.registration || !this.registration.waiting) { 
      //   console.log('will not execute...')
      //   return 
      // }
      this.registration.waiting.postMessage({ type: 'SKIP_WAITING' })
    }
  }
}
</script>

<style>
.install-app {
  width: 100%;
  text-align: center;
}

</style>