<template>
  <div>
    <div class="row center-align" v-if="resident" style="margin-top: 10px;">
        <div class="container" @click="changeResident">
            <div class="col s4">
                <img v-if="residentHasAvatar" class="circle" :src="residentAvatarUrl(resident.avatar)" style="max-height: 120px;">
                <img v-if="residentHasNoAvatarAndFemale" src="../assets/grandma.png" style="max-height: 120px;">
                <img v-if="residentHasNoAvatarAndMale" src="../assets/grandpa.png" style="max-height: 120px;">
            </div>
            <div class="col s8">
                <p style="font-size: 1.5rem">{{ resident.fname }} {{ resident.lname }}</p>
                <span v-if="resident.pivot">
                  <p v-if="resident.pivot.personal_nickname !== null" style="font-size: 1.1rem; font-style: italic; margin-top: -10px;">{{ resident.pivot.personal_nickname }}</p>
                </span>
                <p v-else style="font-size: 1.1rem; font-style: italic; margin-top: -10px;">{{ resident.nickname }}</p>
            </div>
        </div>
    </div>
    <AddMedia/>
    <NewsFeed/>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header'
import Footer from '@/components/Footer'
import AddMedia from '@/components/AddMedia.vue'
import NewsFeed from '@/components/NewsFeed.vue'
import store from '../store'

export default {
  name: 'home',
  data () {
    return {
      resident: null
    }
  },
  components: {
    Header,
    Footer,
    AddMedia,
    NewsFeed
  },
  mounted() {
    this.resident = this.$store.state.residents[this.$store.state.activeResident]
    // update account information when mounted so we have the latest information
    store.dispatch('fetchActiveResidentAccount')
  },
  methods: {
    changeResident() {
      if(this.$store.state.residents.length > 1)
      {
        console.log('we will show the view to switch residents')
        this.$router.push({ name: 'switch-resident' })
      }
    },

    residentAvatarUrl(avatar) {
      return process.env.VUE_APP_SERVER_URL + '/nas/' + avatar
    }
  },
  computed: {
    residentHasAvatar() {
      if(this.resident.media.length > 0) {
        return true
      }
      return false
    },
    residentHasNoAvatarAndFemale() {
      if(!this.resident.media.length > 0 && this.resident.sexe == 'female'){
        return true
      }
      return false
    },
    residentHasNoAvatarAndMale() {
      if(!this.resident.media.length > 0 && this.resident.sexe == 'male'){
        return true
      }
      return false
    },
  },
}
</script>