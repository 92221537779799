<template>
    <!-- Modal Structure -->
    <div id="confirm-participation" class="modal bottom-sheet" style="margin-bottom: 60px !important;">
        <div class="modal-content">
            <div v-show="choicesToBeMade.length > 0">
                <h4>Maak uw keuze!</h4>
                <!-- Create a form that allows the user to make his or her choice -->
                <!-- We need a form element per entry in choicesToBeMade -->
                <div v-for="(choice, index) in choicesToBeMade" :key="index">
                    <!-- if choice is an array we need to provide radio buttons -->
                    <div v-if="Array.isArray(choice)">
                        <form :id="`choice_${choice[0].pivot.option_group}`">
                            <p>
                                <!-- if on of the elements in the array has it's pivot.is_optional set to true, we need to add a radio button for an empty choice -->
                                <label v-if="choice.some(product => product.pivot.is_optional)">
                                    <input :name="`group_${choice[0].pivot.option_group}`" type="radio" id="0"/>
                                    <span>Geen keuze</span>
                                </label>

                                <label v-for="product in choice" :key="product.id">
                                    <input :name="`group_${product.pivot.option_group}`" type="radio" :id="product.id" :checked="product.pivot.is_default"/>
                                    <span>{{ product.title }}</span>
                                </label>
                            </p>
                        </form>
                    </div>
                    <div v-else>
                        <form :id="`choice_${choice.pivot.option_group}`">
                            <p>
                                <label>
                                    <input :name="`group_${choice.pivot.option_group}`" type="checkbox" :id="`checkbox_${choice.id}`" :checked="choice.pivot.is_default"/>
                                    <span>{{ choice.title }}</span>
                                </label>
                            </p>
                        </form>
                    </div>
                </div>
            </div>
            <div v-if="user.partner_id !== undefined && user.partner_id !== null">
                <h5>Zal uw partner er ook bij zijn?</h5>
                <label>
                    <input :name="`partner_${user.partner_id}`" type="checkbox" :id="`checkbox_${user.partner_id}`" :checked="true" />
                    <span>Met partner ({{ user.partner.fname }} {{ user.partner.lname }})</span>
                </label>
            </div>
            <h4 style="margin-top: 10px;">Bevestig uw deelname!</h4>
            <ul class="collection">
                <li class="collection-item modal-option" @click="closeModalConfirmParticipation">
                    <font-awesome-icon style="cursor: pointer;" icon="times" /> Nee toch maar niet
                </li>
                <li class="collection-item modal-option modal-option-confirm" @click="confirmedParticipation">
                    <font-awesome-icon style="cursor: pointer;" icon="check" /> Ik zal er zijn!
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import swal from 'sweetalert'

export default {
    name: "confirm-participation",
    data() {
        return {
            modal: null
        }
    },
    mounted() {
        this.modal = M.Modal.init(document.getElementById('confirm-participation'), {
            dismissible: false,
            onCloseEnd: () => {
                this.$emit('close')
            }
        })
        this.modal.open()
    },
    props: {
        meal: {
            type: Object,
            required: true
        },
        choicesToBeMade: {
            type: Array,
            required: true
        },
        user: {
            type: Object,
            required: true
        },
    },
    methods: {
        confirmedParticipation()
        {
            /* Create an empty array to store the choices */
            let choices = []
            /* Check if the user has made a choice for each entry in choicesToBeMade */
            if (this.choicesToBeMade.length > 0)
            {
                /* Go through each entry in choicesToBeMade */
                this.choicesToBeMade.forEach( choice => {
                    /* Check if the choice is an array */
                    if (Array.isArray(choice))
                    {
                        /* Find the radio group selected value for this choice */
                        /** This can be found in the form with the id `choice_${choice[0].pivot.option_group}`, the radio group input elements have the name attribute set to `group_${choice[0].pivot.option_group}` */
                        /** Push the option group and chosen value as key value pair to the choices array */
                        choices.push({
                            option_group: choice[0].pivot.option_group,
                            value: document.querySelector(`input[type='radio'][name='group_${choice[0].pivot.option_group}']:checked`).id
                        })
                        // console.log(`${choice[0].pivot.option_group}: ${document.querySelector(`input[type='radio'][name='group_${choice[0].pivot.option_group}']:checked`).id}`)
                    } else {
                        /* Find the checkbox value for this choice */
                        /** This can be found in the form with the id `choice_${choice.pivot.option_group}`, the checkbox input element has the name attribute set to `group_${choice.pivot.option_group}` */
                        /** Push the option group and chosen value as key value pair to the choices array */
                        choices.push({
                            option_group: choice.pivot.option_group,
                            value: document.getElementById(`checkbox_${choice.id}`).checked
                        })
                        // console.log(`${choice.pivot.option_group}: ${document.getElementById(`checkbox_${choice.id}`).checked}`)
                    }
                })
            }
            
            /* Do a post request to the server to confirm the participation */
            axios.post(`${process.env.VUE_APP_SERVER_URL}/api/meals/confirm-participation`, {
                meal_id: this.meal.id,
                choices: choices,
                user: this.user
            }, {
                headers: {
                    'Authorization': `Bearer ${this.$store.state.auth.auth_token}`
                }
            }).then((res) => {
                /* Check the response.data.status value. If 'success' then show a success message, otherwise show an error message */
                if(res.data.status)
                {
                    swal({
                        title: "Deelname bevestigd!",
                        text: "Uw deelname is bevestigd, tot dan!",
                        icon: "success",
                        button: "Oké"
                    }).then(() => {
                        this.closeModalConfirmParticipation()
                        this.$emit('confirmed')
                    })
                } else {
                    swal({
                        title: "Er is iets misgegaan!",
                        text: res.data.message,
                        icon: "error",
                        button: "Oké"
                    }).then(() => {
                        this.closeModalConfirmParticipation()
                    })
                }
            }).catch((err) => {
                swal({
                    title: "Er is iets misgegaan!",
                    text: "Er is iets misgegaan, probeer het later opnieuw.",
                    icon: "error",
                    button: "Oké"
                }).then(() => {
                    this.closeModalConfirmParticipation()
                })
            })
        },
        closeModalConfirmParticipation()
        {
            this.modal.close()
            this.$emit('close')
        }
    }
}

</script>