<template>
  <div class="collection" style="text-align: center;">
    <!-- <router-link v-if="user.group == 'family-admin-user'" to="settings-users" class="collection-item">
        <h4>Deelnemers <i class="material-icons fp-footer-icon">group</i></h4>
    </router-link>
    <router-link v-if="user.group == 'family-admin-user'" to="settings-resident" class="collection-item">
        <h4>Resident <i class="material-icons fp-footer-icon">person</i></h4>
    </router-link> -->
    <router-link to="link-resident" class="collection-item">
        <h5>{{ $t('settings.addResident') }} <i class="material-icons fp-footer-icon">add</i></h5>
    </router-link>
    <router-link to ="settings-general" class="collection-item">
        <h5>{{ $t('settings.general') }} <i class="material-icons fp-footer-icon">app_settings_alt</i></h5>
    </router-link>
    <router-link to ="settings-notifications" class="collection-item">
        <h5>{{ $t('settings.notifications') }} <i class="material-icons fp-footer-icon">notification_important</i></h5>
    </router-link>
    <router-link to="settings-privacy" class="collection-item">
        <h5>{{ $t('settings.privacy') }} <i class="material-icons fp-footer-icon">lock</i></h5>
    </router-link>
    <a @click="logout()" class="collection-item">
        <h5>{{ $t('settings.logout') }} <i class="material-icons fp-footer-icon">exit_to_app</i></h5>
    </a>
    <div>
        {{ $t('settings.version') }} {{ show_version() }}
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import swal from "sweetalert";

import store from "../store"

export default {
  components: {
  },
  name: "settings",
  data() {
    return {
      user: {}
    }
  },
  created() {
  },
  mounted() {
    this.user = store.state.user
  },
  methods: {
    logout() {
        this.$store.dispatch('logout')
        this.$router.push('/login')
    },
    show_version() {
      return process.env.VUE_APP_VERSION
    }
  }
};
</script>

<style scoped>

h5 {
  display: flex;
  justify-content: space-between;
}

</style>