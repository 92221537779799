<template>
  <div>
    <Header :key="'header_' + $route.fullPath" v-if="!fullscreen" />
    <div class="main-content" :class="{ 'main-content-fullscreen': fullscreen }">
      <router-view @activatefullscreen="switchToFullscreen" @deactivatefullscreen="switchToNormalMode"></router-view>
    </div>
    <Footer :key="'footer_' + $route.fullPath" v-if="!fullscreen" />
  </div>
</template>

<script>
// @ is an alias to /src
import Home from '@/views/Home'
import Header from '@/components/Header'
import Footer from '@/components/Footer'
import store from '../store'
import Echo from 'laravel-echo'

export default {
  name: 'App',
  data () {
    return {
      resident: null,
      echoClient: null,
      fullscreen: false,
    }
  },
  components: {
    Header,
    Footer,
  },
  methods: {
    switchToFullscreen() {
      console.log('we will now switch to fullscreen')
      console.log('thank you we will now set fullscreen to true')
      this.fullscreen = true
      console.log(`fullcreen value should now be ${this.fullscreen}`)
    },
    switchToNormalMode() {
      console.log('we will now switch to normal mode')
      this.fullscreen = false
    }
  },
  mounted() {
    this.resident = store.state.residents[store.state.activeResident]

    this.echoClient = new Echo({
      broadcaster: 'pusher',
      key: process.env.VUE_APP_WEBSOCKETS_APP_ID,
      wsHost: process.env.VUE_APP_WEBSOCKETS_URL,
      wssPort: process.env.VUE_APP_WEBSOCKETS_PORT,
      forceTLS: true,
      disableStats: true,
      cluster: process.env.VUE_APP_WEBSOCKETS_APP_CLUSTER,
      authEndpoint: process.env.VUE_APP_SERVER_URL + '/api/broadcasting/auth',
      enabledTransports: ['ws', 'wss'],
      auth: {
        headers: {
          Authorization: "Bearer " + store.state.auth.auth_token,
          Accept: "application/json"
        }
      }
    })

    this.echoClient.private('App.User.' + store.state.user.id)
        .listen('UpdatePosts', (e) => {
            store.dispatch('fetchActiveResidentPosts')
        })

    this.fullscreen = false
  },
  destroyed() {
    this.echoClient.disconnect()
  },
  computed: {
    residentHasAvatar() {
      if(this.resident.avatar) {
        return true
      }
      return false
    },
    residentHasNoAvatarAndFemale() {
      if(!this.resident.avatar && this.resident.sexe == 'f'){
        return true
      }
      return false
    },
    residentHasNoAvatarAndMale() {
      if(!this.resident.avatar && this.resident.sexe == 'm'){
        return true
      }
      return false
    },
  },
}
</script>

<style scoped>

.main-content {
  position: fixed;
  top: 100px;
  bottom: 60px;
  left: 0px;
  right: 0px;
  overflow: auto;
}

.main-content-fullscreen {
  top: 0 !important;
  bottom: 0 !important;
}
</style>