<template>
    <!-- Modal Structure -->
    <div id="confirm-unsubscribe" class="modal bottom-sheet" style="margin-bottom: 60px !important;">
        <div class="modal-content">
            <div v-if="user.partner_id !== undefined && user.partner_id !== null">
                <h5>Partner ook uitschrijven?</h5>
                <label>
                    <input :name="`partner_${user.partner_id}`" type="checkbox" :id="`checkbox_${user.partner_id}`" :checked="true" />
                    <span>Ook mijn partner ({{ user.partner.fname }} {{ user.partner.lname }})</span>
                </label>
            </div>
            <h4 style="margin-top: 10px;">Bevestig uitschrijven!</h4>
            <ul class="collection">
                <li class="collection-item modal-option" @click="closeModalConfirmUnsubscribe">
                    <font-awesome-icon style="cursor: pointer;" icon="times" /> Nee ik kom toch!
                </li>
                <li class="collection-item modal-option modal-option-confirm" @click="confirmedUnsubscribe">
                    <font-awesome-icon style="cursor: pointer;" icon="check" /> Ja ik wil me uitschrijven!
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import swal from 'sweetalert'


export default {
    name: "confirm-unsubscribe",
    data() {
        return {
            modal: null
        }
    },
    mounted() {
        this.modal = M.Modal.init(document.getElementById('confirm-unsubscribe'), {
            dismissible: false,
            onCloseEnd: () => {
                this.$emit('close')
            }
        })
        this.modal.open()
    },
    props: {
        meal: {
            type: Object,
            required: true
        },
        user: {
            type: Object,
            required: true
        },
    },
    methods: {
        confirmedUnsubscribe()
        {
            /* Do a post request to the server to confirm the unsubscribe */
            axios.post(`${process.env.VUE_APP_SERVER_URL}/api/meals/confirm-unsubscribe`, {
                meal_id: this.meal.id,
                user: this.user
            }, {
                headers: {
                    'Authorization': `Bearer ${this.$store.state.auth.auth_token}`
                }
            }).then((res) => {
                /* Check the response.data.status value. If 'success' then show a success message, otherwise show an error message */
                if(res.data.status)
                {
                    swal({
                        title: "Uitschrijven bevestigd!",
                        text: "Uw annulatie is bevestigd!",
                        icon: "success",
                        button: "Oké"
                    }).then(() => {
                        this.closeModalConfirmUnsubscribe()
                        this.$emit('confirmed')
                    })
                } else {
                    swal({
                        title: "Er is iets misgegaan!",
                        text: res.data.message,
                        icon: "error",
                        button: "Oké"
                    }).then(() => {
                        this.closeModalConfirmUnsubscribe()
                    })
                }
            }).catch((err) => {
                swal({
                    title: "Er is iets misgegaan!",
                    text: "Er is iets misgegaan, probeer het later opnieuw.",
                    icon: "error",
                    button: "Oké"
                }).then(() => {
                    this.closeModalConfirmUnsubscribe()
                })
            })
        },
        closeModalConfirmUnsubscribe()
        {
            this.modal.close()
            this.$emit('close')
        }
    }
}

</script>