import Vue from 'vue'
import Vuex, { Store } from 'vuex'
Vue.use(Vuex)

const INITIALISE_STORE = "INITIALISE_STORE"
const LOGIN = "LOGIN"
const LOGIN_SUCCESS = "LOGIN_SUCCESS"
const SET_AUTH_INFO = "SET_AUTH_INFO"
const LOGOUT = "LOGOUT"
const SET_USER_INFORMATION = "SET_USER_INFORMATION"
const TOGGLE_KEY_HAS_RESIDENTS = "TOGGLE_KEY_HAS_RESIDENTS"
const SET_USER_RESIDENTS = "SET_USER_RESIDENTS"
const SET_ACTIVE_RESIDENT = "SET_ACTIVE_RESIDENT"
const SET_ACCOUNT_FOR_ACTIVE_RESIDENT = "SET_ACCOUNT_FOR_ACTIVE_RESIDENT"
const SET_POSTS_FOR_ACTIVE_RESIDENT = "SET_POSTS_FOR_ACTIVE_RESIDENT"
const RESET_STATE = "RESET_STATE"
const DELETE_POST_FROM_STATE = "DELETE_POST_FROM_STATE"
const ADD_POST_TO_STATE = "ADD_POST_TO_STATE"
const SET_USER_SETTINGS = "SET_USER_SETTINGS"

const getDefaultState = () => {
  return {
    loggedIn: false,
    auth: {},
    pending: false,
    hasResidents: false,
    residents: [],
    user: {},
    activeResident: null,
    postsForActiveResident: [],
    accountForActiveResident: {},
  }
}

// initial state
const state = getDefaultState()

export default new Vuex.Store({
    state,
    mutations: {
        [SET_USER_SETTINGS] (state, value) {
            state.user.settings = value
        },
        [DELETE_POST_FROM_STATE] (state, post_id) {
            let index = state.postsForActiveResident.findIndex((post) => {
                return post.id == post_id
            })
            state.postsForActiveResident.splice(index, 1)
        },
        [ADD_POST_TO_STATE] (state, post) {
            state.postsForActiveResident.push(post)
        },
        [RESET_STATE] (state) {
            Object.assign(state, getDefaultState())
        },
        [INITIALISE_STORE] (state) {
            // BEFORE PRODUCTION !!!!!!!!!!!!!!!!!!!!!! the token could be expired!!! how to re login????
            // we don't care for now. We have a axios interceptor defined. whenever unauthorized you get logged out and back to login screen
            if(localStorage.getItem('store')) {
                // Replace the state object with the stored item
                this.replaceState(
                    Object.assign(state, JSON.parse(localStorage.getItem('store')))
                )
            }
        },
        [LOGIN] (state) {
            state.pending = true
        },
        [LOGIN_SUCCESS] (state) {
            state.loggedIn = true
            state.pending = false
        },
        [SET_AUTH_INFO] (state, auth_info) {
            state.auth =  auth_info
        },
        [LOGOUT] (state) {
            state.loggedIn = false
        },
        [TOGGLE_KEY_HAS_RESIDENTS] (state, value) {
            state.hasResidents = value
        },
        [SET_USER_INFORMATION] (state, user) {
            state.user = user
        },
        [SET_USER_RESIDENTS] (state, residents) {
            state.residents = residents
        },
        [SET_ACTIVE_RESIDENT] (state, residentIndex) {
            state.activeResident = residentIndex
            // how to instruct APP.VUE to reload the resident...
        },
        [SET_ACCOUNT_FOR_ACTIVE_RESIDENT] (state, account) {
            state.accountForActiveResident = account
        },
        [SET_POSTS_FOR_ACTIVE_RESIDENT] (state, posts) {
            state.postsForActiveResident = posts
        }
    },
    getters: {
        isLoggedIn: state => {
            return state.loggedIn
        },
        hasResidentsLinked: state => {
            return state.hasResidents
        },
        loginPending: state => {
            return state.pending
        },
        postsSorted: state => {
            return state.postsForActiveResident.sort((a, b) => {
                let dateA = a.created_at.replace(/ /g, 'T')
                let dateB = b.created_at.replace(/ /g, 'T')
                return new Date(dateB) - new Date(dateA)
            })
        },
        activeResidentObject: state => {
            return state.residents[state.activeResident]
        }
    },
    actions: {
        checkAuthToken({ commit, state }) {
            // method to check the current auth token
            // if not valid always go back to login
        },
        login({ commit }, creds) {
            commit(LOGIN)
            return new Promise((resolve, reject) => {
                axios.post(process.env.VUE_APP_SERVER_URL + '/oauth/token', {
                    'grant_type': 'password',
                    'client_id': process.env.VUE_APP_LARAVEL_PASSPORT_ID,
                    'client_secret': process.env.VUE_APP_LARAVEL_PASSPORT_KEY,
                    'username': creds.email,
                    'password': creds.password,
                    'scope': '*'
                }).then((response) => {
                    commit(SET_AUTH_INFO, { 'auth_token': response.data.access_token, 'auth_token_type': response.data.token_type })
                    commit(LOGIN_SUCCESS)
                    resolve()
                }).catch((error) => {
                    reject(error)
                })
            })
        },
        logout({ commit, state }) {
            // clear store
            commit(RESET_STATE)
            // clear localstorage
            localStorage.setItem('store', {})

            // bye bye
            commit(LOGOUT)
        },
        userInformation({ commit, state }) {
            return new Promise((resolve, reject) => {
                axios.get(process.env.VUE_APP_SERVER_URL + '/api/user-information', {
                    headers: {
                        'Authorization': 'Bearer ' + state.auth.auth_token
                    }
                }).then((response) => {
                    if(response.data.user) {
                        commit(SET_USER_INFORMATION, response.data.user)
                        resolve()
                    } else {
                        console.error('There is no user return')
                        console.error(response.data)
                    }
                }).catch((error) => {
                    console.error(error)
                    reject(error)
                })
            })
        },
        fetchLinkedResidents({ commit, state }) {
            return new Promise((resolve, reject) => {
                axios.get(process.env.VUE_APP_SERVER_URL + '/api/user-linked-residents', {
                    headers: {
                        'Authorization': 'Bearer ' + state.auth.auth_token
                    }
                }).then((response) => {
                    if(response.data.residents.length <= 0) {
                        commit(TOGGLE_KEY_HAS_RESIDENTS, false)
                        // Check if the user is linked to an account. If so we need to resolve with a notice that we can only fetch account information.
                        // If not we need to reject because we can't do anything without a resident
                        if(state.user.account_id != null) {
                            resolve('no-residents-but-account')
                        } else {
                            console.log('going to reject')
                            reject()
                        }
                    } else {
                        commit(TOGGLE_KEY_HAS_RESIDENTS, true)
                        commit(SET_USER_RESIDENTS, response.data.residents)
                        // if there is no active residents set we better set it to the first resident found.
                        // otherwise do nothing because we already set a resident apparantly
                        if(this.state.activeResident == null || !isNaN(this.state.activeResident) || this.state.activeResident > this.state.residents.length) {
                            commit(SET_ACTIVE_RESIDENT, 0)
                        }
                        resolve()
                    }
                })
            })
        },
        fetchActiveResidentPosts({ commit, state }) {
            return new Promise((resolve, reject) => {
                if (state.hasResidents) {
                    axios.post(process.env.VUE_APP_SERVER_URL + '/api/resident-linked-posts', { 'id': state.residents[state.activeResident].id }, {
                        headers: {
                            'Authorization': 'Bearer ' + state.auth.auth_token
                        }
                    }).then((response) => {
                        commit(SET_POSTS_FOR_ACTIVE_RESIDENT, response.data.posts)
                        resolve()
                    }).catch((err) => {
                        console.error(err)
                        reject(err)
                    })
                } else {
                    axios.post(process.env.VUE_APP_SERVER_URL + '/api/account-linked-posts', { 'id': state.user.account_id }, {
                        headers: {
                            'Authorization': 'Bearer ' + state.auth.auth_token
                        }
                    }).then((response) => {
                        commit(SET_POSTS_FOR_ACTIVE_RESIDENT, response.data.posts)
                        resolve()
                    }).catch((err) => {
                        console.error(err)
                        reject(err)
                    })
                }
            })
        },
        fetchActiveResidentAccount({ commit, state }) {
            return new Promise((resolve, reject) => {
                let accountId = null
                if (state.hasResidents) {
                    accountId = state.residents[state.activeResident].account_id
                } else {
                    accountId = state.user.account_id
                }
                axios.post(process.env.VUE_APP_SERVER_URL + '/api/resident-linked-account', { 'id': accountId }, {
                    headers: {
                        'Authorization': 'Bearer ' + state.auth.auth_token
                    }
                }).then((response) => {
                    // fill the account for the current active resident information to the store
                    commit(SET_ACCOUNT_FOR_ACTIVE_RESIDENT, response.data.account)
                    resolve()
                }).catch((err) => {
                    console.log(err.response)
                    reject(err)
                })
            })
        },
        saveUserSettings({ commit, state}, settings) {
            return new Promise((resolve, reject) => {
                axios.post(process.env.VUE_APP_SERVER_URL + '/api/set-user-settings', settings, {
                    headers: {
                        'Authorization': 'Bearer ' + state.auth.auth_token
                    }
                }).then((response) => {
                    commit(SET_USER_SETTINGS, response.data.settings)
                    resolve()
                }).catch((err) => {
                    console.error(err)
                    reject(err)
                })
            })
        }
    }
})