<template>
  <div class="container">
    <div class="row" v-if="news.length > 0">
      <div class="col s12 m6" v-for="item in news" :key="item.id">
        <div class="card">
          <div class="card-image">
            <img v-if="item.media.length > 0" :src="newsImage(item.media[0])">
            <a class="btn-floating halfway-fab waves-effect waves-light red">
              <font-awesome-icon icon="newspaper" size="2x" transform="right-3 down-3" />
            </a>
          </div>
          <div class="card-content">
            <span class="card-title">{{ item.title }} - {{ item.date }}</span>
            <p>{{ item.description }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-else>
      {{ $t('news.noNews') }}
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import swal from 'sweetalert'
import store from '../../store'

export default {
  components: {
  },
  name: 'news',
  data() {
    return {
      news: []
    }
  },
  created() {
  },
  mounted() {
    // update account information when mounted so we have the latest information
    store.dispatch('fetchActiveResidentAccount')
    let newsItems = []
    store.state.accountForActiveResident.users.forEach((user) => {
      newsItems.push(user.news)
    })

    this.news  = [].concat(...newsItems)

    this.news.sort(function(a,b){
      // Turn your strings into dates, and then subtract them
      // to get a value that is either negative, positive, or zero.
      return new Date(b.updated_at) - new Date(a.updated_at);
    });

  },
  methods: {
    newsImage(image)
    {
      return `${process.env.VUE_APP_SERVER_URL}/storage/${image.id}/${image.file_name}`
    }
  }
};
</script>

<style scoped>

</style>