<template>
  <div class="container">
    <div class="row center-align">
      <img src="../../assets/logo.png">
    </div>
    <div class="row">
        <form class="col s12">
            <div clas="row">
                <div class="input-field col s12" @click="setFocusTextArea('fname')">
                    <input type="text" id="fname" v-model="fname" class="validate" ref="fname">
                    <label for="name">{{ $t('register.fname') }}</label>
                </div>
            </div>
            <div clas="row">
                <div class="input-field col s12" @click="setFocusTextArea('lname')">
                    <input type="text" id="lname" v-model="lname" class="validate" ref="lname">
                    <label for="name">{{ $t('register.lname') }}</label>
                </div>
            </div>
            <div clas="row">
                <div class="input-field col s12" @click="setFocusTextArea('email')">
                    <input type="email" id="email" v-model="email" class="validate" ref="email">
                    <label for="email">{{ $t('register.email') }}</label>
                </div>
            </div>
            <div clas="row">
                <div class="input-field col s12" @click="setFocusTextArea('password')">
                    <input type="password" id="password" v-model="password" class="validate" ref="password">
                    <label for="password">{{ $t('register.password') }}</label>
                </div>
            </div>
            <div class="row">
                <button v-show="!registerLoading" type="submit" @click.prevent="register" name="login" class="col s12 btn btn-large waves-effect pink darken-2">{{ $t('register.register') }}</button>
                <div v-show="registerLoading" class="preloader-wrapper small active">
                  <div class="spinner-layer spinner-green-only">
                    <div class="circle-clipper left">
                      <div class="circle"></div>
                    </div>
                    <div class="gap-patch">
                      <div class="circle"></div>
                    </div>
                    <div class="circle-clipper right">
                      <div class="circle"></div>
                    </div>
                  </div>
                </div>
            </div>
              <div v-if="errorRegistration" class="card-alert card red lighten-5">
                <div class="card-content red-text">
                  <ul>
                    <li v-for="error in errorsRegistration">@{{ error }}</li>
                  </ul>
                </div>
              </div>
        </form>
    </div>
    <div class="row center-align">
      <router-link :to="{ path: 'login' }">
        {{ $t('register.backToLogin') }}
      </router-link>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import swal from 'sweetalert'

import store from '../../store'

export default {
  name: 'register',
  data() {
      return {
        fname: '',
        lname: '',
        email: '',
        password: '',
        registerLoading: false,
        errorRegistration: false,
        errorsRegistration: []
      }
  },
  methods: {
    setFocusTextArea(input) {
      this.$refs[input].focus()
    },
    register() {
      this.registerLoading = true
      // check if everything is filled in on the client side would be a nice addition
      axios.post(process.env.VUE_APP_SERVER_URL + '/api/auth/register', {
        'fname': this.fname,
        'lname': this.lname,
        'email': this.email,
        'password': this.password,
      }).then((response) => {
        store.commit('SET_AUTH_INFO', { 'auth_token': response.data.success.token, 'auth_token_type': 'Bearer' })
        this.handleSuccessfullRegistration(response.data.success.token)
      }).catch((error) => {
        console.error(error)
        this.errorRegistration = true
        this.errorsRegistration = error.response.data.error
        this.registerLoading = false
      })
    },
    handleSuccessfullRegistration(authcode)
    {
      store.commit('LOGIN_SUCCESS')
      store
        .dispatch("userInformation")
        .then(() => {
          // directly push to link-resident since this is a new user...
          this.$router.push('/first-time-link-resident')
        })
        .catch(error => {
          console.log(error);
        });
    },
  },
}
</script>

<style>
.social {
  width: 80%;
}
</style>

