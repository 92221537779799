<template>
  <div id="app">
      <install-prompt v-show="showInstallPrompt" v-on:user-wants-to-install="startInstallProcess"></install-prompt>
      <update-prompt></update-prompt>
      <router-view/>
  </div>
</template>

<script>

import InstallPrompt from './components/InstallPrompt'
import UpdatePrompt from './components/UpdatePrompt'
import * as PusherPushNotifications from '@pusher/push-notifications-web'
import Echo from 'laravel-echo'
import Pusher from 'pusher-js'

import firebase from 'firebase/app'
import 'firebase/analytics'
import 'firebase/messaging'
      

export default {
  data() {
    return {
      deferredPrompt: {},
      showInstallPrompt: false,
      beamsClient: null,
      beamsClientPersonal: null,
      echoClient: null,
      user: this.$store.state.user
    }
  },
  components: {
    InstallPrompt, UpdatePrompt
  },
  methods: {
    // isTokenSentToServer(currentToken) {
    //     return (window.localStorage.getItem('sentFirebaseMessagingToken') === currentToken)
    // },
    // setTokenSentToServer(currentToken) {
    //     window.localStorage.setItem('sentFirebaseMessagingToken', currentToken ? currentToken : '')
    // },
    // sendTokenToServer(currentToken) {
    //     if(!this.isTokenSentToServer(currentToken)) {
    //         axios.post(process.env.VUE_APP_SERVER_URL + '/api/push', { token: currentToken })
    //             .then((data) => {
    //                 if(data.data.status) {
    //                     this.setTokenSentToServer(currentToken)
    //                 }
    //             })
    //     }
    // },
    startInstallProcess() {
      this.showInstallPrompt = false
      this.deferredPrompt.prompt()
      this.deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the install prompt')
        } else {
          console.log('User dismissed the install prompt. Maybe not interested?')
        }
      })
    },
    // urlBase64ToUint8Array(base64String) {
    //   let padding = '='.repeat((4 - base64String.length % 4) % 4)
    //   let base64 = (base64String + padding)
    //     .replace(/\-/g, '+')
    //     .replace(/_/g, '/')

    //   let rawData = window.atob(base64)
    //   let outputArray = new Uint8Array(rawData.length)

    //   for (let i = 0; i < rawData.length; ++i) {
    //     outputArray[i] = rawData.charCodeAt(i)
    //   }
    //   return outputArray
    // },
    storePushSubscription(pushSubscription) {
      axios.post(process.env.VUE_APP_SERVER_URL + '/api/push', {
          currentToken: pushSubscription
          }, {
            headers: {
              'Authorization': 'Bearer ' + this.$store.state.auth.auth_token
            }
          })
        .then((res) => {
          // we are registerd...
        })
        .catch((err) => {
          console.error(err)
        })
    }
  },
  beforeMount() {
  },
  mounted() { 
    let that = this

    window.addEventListener('load', () => {
      if (navigator.standalone) {
        // Installed on an iOS device
        // What should we do for these users?
      } else if (matchMedia('(display-mode: standalone)').matches) {
        // Installed on other device
        // What should we do for these users?
      } else {
        // Not installed we should ask the user to install us.
        window.addEventListener('beforeinstallprompt', (e) => {
          // Prevent Chrome 67 and earlyer to show the promt. We do it ourselves.
          e.preventDefault()
          that.deferredPrompt = e
          that.showInstallPrompt = true
        })
      }
    })

    // this should be done after login and maybe keep the damn client in vuex store?

    // this.echoClient = new Echo({
    //   broadcaster: 'pusher',
    //   key: process.env.VUE_APP_WEBSOCKETS_APP_ID,
    //   wsHost: process.env.VUE_APP_WEBSOCKETS_URL,
    //   wssPort: 6001,
    //   forceTLS: true,
    //   disableStats: true,
    //   cluster: process.env.VUE_APP_WEBSOCKETS_APP_CLUSTER,
    //   authEndpoint: process.env.VUE_APP_SERVER_URL + '/api/broadcasting/auth',
    //   auth: {
    //     headers: {
    //       Authorization: "Bearer " + this.$store.state.auth.auth_token,
    //       Accept: "application/json"
    //     }
    //   }
    // })

    // this.echoClient.channel('App.User')
    //   .listen('UpdatePosts', (e) => {
    //     console.log('event received')
    //     console.log(e)
    //   })

    // this.echoClient.private('App.User.' + this.user.id)
    //   .listen('UpdatePosts', function(e) {
    //     console.log(e)
    //   })

    // if(this.$store.state.loggedIn)
    // {
    //   navigator.serviceWorker.ready
    //     .then((registration) => {
    //       const subscribeOptions = {
    //         userVisibleOnly: true,
    //         applicationServerKey: this.urlBase64ToUint8Array(
    //           process.env.VUE_APP_VAPID_PUBLIC
    //         )
    //       }

    //       return registration.pushManager.subscribe(subscribeOptions)
    //     })
    //     .then((pushSubscription) => {
    //       this.storePushSubscription(pushSubscription)
    //     })
    // }

    if(this.$store.state.loggedIn)
    {
      const firebaseConfig = {
        apiKey: 'AIzaSyCGaalp2INzMgckmNruokhoP3DHvhe1z54',
        authDomain: 'family-portal-319316.firebaseapp.com',
        projectId: 'family-portal-319316',
        storageBucket: 'family-portal-319316.appspot.com',
        messagingSenderId: '863028628073',
        appId: '1:863028628073:web:60fccf8098b123ddd030f8',
        measurementId: 'G-0XS48G9SMC'
      }

      firebase.initializeApp(firebaseConfig)

      const messaging = firebase.messaging()

      navigator.serviceWorker.ready.then((registration) => {
        messaging.getToken({
          vapidKey: 'BNfrbxH3-ZBn3C63W3ADTV_1R24BE9mq7igKsT1kHAis70gT7gMfJCxcgMO9ExatOhgmx_EM7Ph-uf18_0fnvM8',
          serviceWorkerRegistration: registration
        }).then((currentToken) => {
          this.storePushSubscription(currentToken)
          console.log(currentToken)
        }).catch((error) => {
          console.log(error)
        })
      })

      messaging.onMessage((payload) => {
        console.log('Message received. ', payload)
      })
      // messaging.getToken({ vapidKey: '' })
      //   .then((currentToken) => {
      //     if(currentToken) {
      //       this.sendTokenToServer(currentToken)
      //     } else {
      //       console.log('no registrition token available ...')
      //     }
      //   })
      //   .catch((err) => {
      //     console.error('An error occured ', err)
      //   })
    }
  },

}
</script>


<style scoped>
  @media screen and (max-width: 992px){
    nav .brand-logo {
      left: 40px !important;
    }
  }

  .brand-logo {
    padding-left: 120px !important;
  }

  #app {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
  }
</style>
