<template>
  <div>
      <a class="waves-effect waves-light btn-small pink darken-2 install-app" @click="userWantsToInstall"><i class="material-icons left">get_app</i> {{ $t('general.installApp') }}</a>
  </div>
</template>

<script>
export default {
  methods: {
    userWantsToInstall() {
      this.$emit('user-wants-to-install')
    }
  }
}
</script>

<style>
.install-app {
  width: 100%;
  text-align: center;
}

</style>