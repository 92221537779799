<template>
  <div class="container" style="margin-top: 20px;">
    <!-- Create a button that is only visible for users that have the group admin-user, integrator-user, super-admin or manager-user. -->
    <div class="row" v-if="$store.state.hasResidents && ['regular-user', 'admin-user', 'integrator-user', 'super-admin', 'manager-user'].includes($store.state.user.group)">
      <div class="col s12">
        <router-link :to="{ name: 'meals-subscribe-others' }" class="btn waves-effect waves-light">
          <!-- {{ $t('meals.subscribeOthers') }} -->
          Anderen Inschrijven
        </router-link>
      </div>
    </div>
    <div class="row" v-if="meals.length > 0">
      <div class="col s12 m6" v-for="meal in meals" :key="meal.id">
        <div class="card">
          <div class="card-image">
            <img :src="mealImage(meal.media[0])" v-if="meal.media.length > 0">
            <div v-else>

            </div>
            <a class="btn-floating halfway-fab waves-effect waves-light red">
              <font-awesome-icon v-if="meal.type == 'breakfast'" icon="mug-hot" size="2x" transform="right-4 down-3" />
              <font-awesome-icon v-if="meal.type == 'lunch'" icon="utensils" size="2x" transform="right-4 down-3" />
              <font-awesome-icon v-if="meal.type == 'snack'" icon="cookie" size="2x" transform="right-4 down-3" />
              <font-awesome-icon v-if="meal.type == 'dinner'" icon="bread-slice" size="2x" transform="right-3 down-3" />
            </a>
          </div>
          <div class="card-content">
            <span class="card-title">{{ localeType(meal.type) }} - {{ formattedDate(meal.date) }}</span>
            <p v-if="meal.description">{{ meal.description }}</p>
            <p v-else-if="meal.products.length > 0">
              <!-- create a line per meal.products entry with the title an the allergens, sort the entrys based on the product.pivot.option_group -->
              <p v-for="product in sortedProducts(meal.products)" :key="product.id">
                <span v-if="product.length > 1">
                  <!-- we need to show one line with all product.title fields with the word or inbetween the products. Except for the last one -->
                  <span v-for="(productItem, index) in product" :key="productItem.id">
                    {{ productItem.title }}
                    <span v-if="index < product.length - 1">of</span>
                  </span>
                </span>
                <span v-else>
                  {{ product[0].title }}
                </span>

              </p>
            </p>
            <span v-if="meal.track_enrollments">
              <span v-if="Date.parse(meal.ultimate_enrollment_date) && new Date(meal.ultimate_enrollment_date) > new Date()">
                <a v-if="!userIsEnrolledForMeal(meal)" @click.prevent="confirmParticipation(meal.id)" class="btn waves-effect waves-light green" style="width: 100%;">
                    <i class="material-icons left">rocket_launch</i>
                    Inschrijven!
                </a>
                <span v-else>
                  <br />
                  <i>U bent ingeschreven voor deze maaltijd.</i>
                  <a @click.prevent="confirmUnsubscribe(meal.id)" class="btn waves-effect waves-light red" style="width: 100%;">
                      <i class="material-icons left">close</i>
                      Uitschrijven!
                  </a>
                </span>
              </span>
              <span v-else>
                <br />
                <span v-if="userIsEnrolledForMeal(meal)">
                  <i>U bent ingeschreven voor deze maaltijd, maar u kan zich niet meer uitschrijven.</i>
                </span>
                <span v-else>
                  De inschrijvingen voor deze maaltijd zijn gesloten.
                </span>
              </span>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-else>
      {{ $t('meals.noMeals') }}
    </div>
    <MealsSubscribeModal
      v-if="showMealsSubscribeModal"
      :meal="mealToSubscribe" :choicesToBeMade="choicesToBeMade" :user="objectToEnroll()"
      @close="closeModal"
      @confirmed="participationIsConfirmed"
    />

    <MealsUnsubscribeModal
      v-if="showMealsUnsubscribeModal"
      :meal="mealToUnsubscribe" :user="objectToEnroll()"
      @close="closeModalUnsubscribe"
      @confirmed="unsubscribeIsConfirmed"
    />
</div>
</template>

<script>
// @ is an alias to /src
import swal from "sweetalert";
import { format } from 'date-fns';
import { nl } from 'date-fns/locale';
import MealsSubscribeModal from "../../components/meals/MealsSubscribeModal";
import MealsUnsubscribeModal from "../../components/meals/MealsUnsubscribeModal";

import store from '../../store'

export default {
  components: {
    MealsSubscribeModal,
    MealsUnsubscribeModal,
  },
  name: "meals",
  data() {
    return {
      meals: store.state.accountForActiveResident.meals,
      mealToSubscribe: null,
      mealToUnsubscribe: null,
      choicesToBeMade: [],
      showMealsSubscribeModal: false,
      showMealsUnsubscribeModal: false,
    }
  },
  created() {
  },
  mounted() {
    // update account information when mounted so we have the latest information
    store.dispatch('fetchActiveResidentAccount')
  },
  methods: {
    participationIsConfirmed() {
      store.dispatch('fetchActiveResidentAccount').then(() => {
        this.meals = store.state.accountForActiveResident.meals
        this.mealToSubscribe = null
        this.choicesToBeMade = []
      })
    },
    unsubscribeIsConfirmed() {
      store.dispatch('fetchActiveResidentAccount').then(() => {
        this.meals = store.state.accountForActiveResident.meals
        this.mealToUnsubscribe = null
      })
    },
    objectToEnroll() {
      /* If $store.state.hasResidents is true and the property user_is_resident on $store.state.residents[$store.state.activeResident] is true, then we need to enroll the resident. Otherwise we need to enroll the user */
      if (
        store.state.hasResidents
        && store.state.residents[store.state.activeResident].pivot !== undefined
        && store.state.residents[store.state.activeResident].pivot.user_is_resident
      )
      {
        return store.state.residents[store.state.activeResident]
      }
      return store.state.user
    },
    sortedProducts(products) {
      /* first group the products based on their pivot.option_group value */
      let groups = products.slice().reduce((groups, product) => {
        const option_group = product.pivot.option_group;
        if (!groups[option_group]) {
          groups[option_group] = [];
        }
        groups[option_group].push(product);
        return groups;
      }, {});

      return groups

    },
    closeModal() {
      this.showMealsSubscribeModal = false
    },
    closeModalUnsubscribe() {
      this.showMealsUnsubscribeModal = false
    },
    confirmUnsubscribe(mealId)
    {
      this.mealToUnsubscribe = this.meals.find(meal => meal.id == mealId)
      this.showMealsUnsubscribeModal = true
    },
    confirmParticipation(mealId) {
      this.mealToSubscribe = this.meals.find(meal => meal.id == mealId)
      // first check if there are choices to be made for this meal
      // For this we need to check 2 things:
      // 1. Does the meal have a product where the pivot table has a is_optional value of 1
      // 2. Does the meal have several products where the pivot table value of option_group is the same
      // On of these 2 conditions is true, we need to show a modal where the user can choose which product he wants to eat
      // If none of these conditions are true, we can just confirm the participation
      let meal = this.meals.find(meal => meal.id == mealId)
      let optionalProducts = meal.products.filter(product => product.pivot.is_optional == 1)

      // check if there are products that have the same option_group set in the pivot table
      let optionGroups = []
      meal.products.forEach(product => {
        if(product.pivot.option_group != null)
        {
          optionGroups.push(product.pivot.option_group)
        }
      })
      let uniqueOptionGroups = [...new Set(optionGroups)]
      if(optionalProducts.length > 0 || uniqueOptionGroups.length > 0)
      {
        // we need to set the choicesToBeMade variable so we can show the choices in the modal
        // we want an array of products for this meal sorted by option_group integer
        // this array should hold all products that are optional and all products that have the same option_group
        // we can then loop over this array and show the products grouped by option_group
        let productsWithChoices = []

        // add all products that have the same option_group. only the ones where the option_group occurs at least twice
        // this is because we don't want to show products that have a unique option_group
        // we want these products with the same option_group to be grouped together in a nested array
        uniqueOptionGroups.forEach(optionGroup => {
          let productsWithSameOptionGroup = meal.products.filter(product => product.pivot.option_group == optionGroup)
          if(productsWithSameOptionGroup.length > 1)
          {
            productsWithChoices.push(productsWithSameOptionGroup)
          }
        })

        // now add all products that are optional
        // keep in mind that the productsWithChoices array can contain nested arrays
        // and we only want to add the optional products if the option_group of the product is not already in the array
        // so we will loop over the optional products and check if the option_group is already in the array
        // if it is not, we will add the product to the array
        optionalProducts.forEach(product => {
          let optionGroupAlreadyInArray = false
          productsWithChoices.forEach(productWithChoice => {
            if(Array.isArray(productWithChoice))
            {
              if(productWithChoice[0].pivot.option_group == product.pivot.option_group)
              {
                optionGroupAlreadyInArray = true
              }
            }
            else
            {
              if(productWithChoice.pivot.option_group == product.pivot.option_group)
              {
                optionGroupAlreadyInArray = true
              }
            }
          })
          if(!optionGroupAlreadyInArray)
          {
            productsWithChoices.push(product)
          }
        })

        // now we need to sort the productsWithChoices array by option_group
        // keep in mind that the productsWithChoices array can contain nested arrays
        // so we need to check if the array is nested or not
        // if it is nested, we need to sort the nested array by option_group of the first product in that nested array
        // if it is not nested, we can just sort the array by option_group of the product
        productsWithChoices.sort((a, b) => {
          if(Array.isArray(a))
          {
            // check if b is also an array
            if(Array.isArray(b))
            {
              return a[0].pivot.option_group - b[0].pivot.option_group
            }
            else
            {
              return a[0].pivot.option_group - b.pivot.option_group
            }
          }
          else
          {
            // check if b is an array
            if(Array.isArray(b))
            {
              return a.pivot.option_group - b[0].pivot.option_group
            }
            else
            {
              return a.pivot.option_group - b.pivot.option_group
            }
          }
        })

        this.choicesToBeMade = productsWithChoices
      }

      this.showMealsSubscribeModal = true

    },
    confirmedParticipation() {
      console.log('and now put it all in the database')
    },
    closeModalConfirmParticipation() {
      this.modal.close()
    },
    userIsEnrolledForMeal(meal) {
      /* If the active resident (store.state.residents[store.state.activeResident]) has a property pivot and this property has a property user_is_resident set to true, we need to check for the active resident id instead of the user id */
      if (store.state.residents[store.state.activeResident].pivot !== undefined && store.state.residents[store.state.activeResident].pivot.user_is_resident)
      {
        return meal.enrollments.some(enrollment => enrollment.resident_id == store.state.residents[store.state.activeResident].id)
      } else {
        return meal.enrollments.some(enrollment => enrollment.user_id == store.state.user.id)
      }
    },
    mealImage(media) {
      return `${process.env.VUE_APP_SERVER_URL}/storage/${media.id}/${media.file_name}`
    },
    formattedDate(date) {
      return format(new Date(date), 'dd MMM', { locale: nl })
    },
    localeType(type) {
      if(type == 'breakfast')
      {
        return this.$t('meals.breakfast')
      }
      else if (type == 'lunch')
      {
        return this.$t('meals.lunch')
      }
      else if (type == 'dinner')
      {
        return this.$t('meals.dinner')
      }
      else if (type == 'snack')
      {
        return this.$t('meals.snack')
      }
    }
  }
};
</script>

<style scoped>

</style>