<template>
<div clas="container">
    <div class="row" v-if="pushNotificationsAllowedInBrowser == 'default' || pushNotificationsAllowedInBrowser == 'granted'">
        <div class="label" style="margin-top: 20px;">
            <div class="col s10">
                {{ $t('settings.notificationsPush') }}
            </div>
            <div class="col s2">
                <onoff-toggle v-model="currentStatusPushNotifications" theme="material" />
            </div>
        </div>
        <div class="description">
            <div class="col s12" style="margin-top: 20px;">
                <i>{{ $t('settings.notificationsPushDesc') }}</i>
            </div>
        </div>
    </div>
    <div class="row" v-else-if="pushNotificationsAllowedInBrowser == 'denied'">
        <div class="label" style="margin-top: 20px;">
            <div class="col s10">
                {{ $t('settings.notificationsPush') }}
            </div>
        </div>
        <div class="description">
            <div class="col s12" style="margin-top: 20px;">
                <i>{{ $t('settings.notificationsPushBlockedDesc') }}</i>
            </div>
        </div>
    </div>
    <div class="row" v-else>
        <div class="label" style="margin-top: 20px;">
            <div class="col s10">
                {{ $t('settings.notificationsPush') }}
            </div>
        </div>
        <div class="description">
            <div class="col s12" style="margin-top: 20px;">
                <i>{{ $t('settings.notificationsPushNotSupportedDesc') }}</i>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="label" style="margin-top: 20px;">
            <div class="col s10">
                {{ $t('settings.notificationsEmail') }}
            </div>
            <div class="col s2">
                <onoff-toggle v-model="currentStatusEmailNotifications" theme="material" />
            </div>
        </div>
        <div class="description">
            <div class="col s12" style="margin-top: 20px;">
                <i>{{ $t('settings.notificationsEmailDesc') }}</i>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    data() {
        return {
            settings: JSON.parse(this.$store.state.user.settings),
        }
    },
    computed: {
        pushNotificationsAllowedInBrowser() {
            return Notification.permission
        },
        currentStatusEmailNotifications: {
            get() {
                return this.settings.notifications.email
            },
            set(newValue) {
                if(!newValue) {
                    this.settings.notifications.email = false
                } else {
                    this.settings.notifications.email = true
                }

                this.saveSettingsToDatabase()
            }
        },
        currentStatusPushNotifications: {
            get() {
                return this.settings.notifications.push
            },
            set(newValue) {
                if(!newValue) {
                    // store false for push notifications
                    this.settings.notifications.push = false
                    this.saveSettingsToDatabase()
                } else {

                    // you are trying to set from false to true
                    // if eg. it fails we have to go back to false
                    // we check to see if we can have the permissions from the user
                    Notification.requestPermission().then((result) => {
                        if(result == 'granted') {
                            // now store a truthy value in the user json settings!
                            this.settings.notifications.push = true
                        } else {
                            // the user disallowed notifications we go back to the false setting
                            this.settings.notifications.push = false
                        }
                        this.saveSettingsToDatabase()
                    })
                }
            }
        }
    },
    methods: {
        saveSettingsToDatabase() {
            // console.log('store the following object to the server')
            // console.log(this.settings)
            this.$store.dispatch('saveUserSettings', {
                settings: this.settings
            })
        },
    },
}
</script>

<style scoped>

</style>