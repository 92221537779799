<template>
    <div class="col s12 m8 offset-m2 l6 offset-l3">
        <div class="row valign-wrapper" style="margin-left: 10px;">
            <a class="waves-effect waves-light btn" style="margin-right: 5px;"><i class="material-icons left">thumb_up</i>3</a>
            <a class="waves-effect waves-light btn" style="margin-right: 5px;"><i class="material-icons left">favorite</i>1</a>
            <a class="waves-effect waves-light btn" style="margin-right: 5px;"><i class="material-icons left">celebration</i>8</a>

        </div>
        <div class="row valign-wrapper">
            <div class="col s2">
                <img src="https://image.shutterstock.com/image-vector/man-smiling-profile-260nw-1137525452.jpg" alt="" class="circle responsive-img"> <!-- notice the "circle" class -->
            </div>
            <div class="col s10">
                <div class="alert card amber lighten-4 brown-text">
                    <div class="card-content">
                        <p><i class="material-icons" style="margin-right: 10px;">report_problem</i><span>Goedkeuren</span> of verwijderen.</p>
                    </div>
                </div>
                <span class="black-text">
                    Super leuk!
                </span>
            </div>
        </div>
        <div class="row valign-wrapper">
            <div class="col s2">
                <img src="https://avatoon.me/wp-content/uploads/2021/09/Cartoon-Pic-Ideas-for-DP-Profile11.png" alt="" class="circle responsive-img"> <!-- notice the "circle" class -->
            </div>
            <div class="col s10">
                <div class="input-field col s12">
                    <textarea id="textarea2" class="materialize-textarea" data-length="120"></textarea>
                    <label for="textarea2">Uw commentaar</label>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style>

</style>