<template>
  <div class="container">
    <div class="logo">
      <img src="../assets/logo.png">
    </div>
    <div v-html="$t('settings.privacyPolicy')"></div>
  </div>
</template>

<script>
// @ is an alias to /src
import swal from "sweetalert";

export default {
  components: {
  },
  name: "settings",
  data() {
    return {
    }
  },
  created() {
  },
  mounted() {
  },
  methods: {
  }
};
</script>

<style scoped>

.logo {
  width: 100%;
  text-align: center;
}

.logo img {
}

</style>