<template>
    <footer v-if="isLoggedIn" class="fp-footer">
        {{ this.$route.currentRoute }}
        <router-link to="/">
          <font-awesome-icon :icon="['fas', 'home']" size="2x" color="#111111" v-if="currentRoute == 'home'"></font-awesome-icon>
          <font-awesome-icon :icon="['fal', 'home']" size="2x" color="#111111" v-else></font-awesome-icon>
        </router-link>
        <router-link to="/meals" v-if="this.account.type != 'family'">
          <font-awesome-icon :icon="['fas', 'utensils']" size="2x" color="#111111" v-if="currentRoute == 'meals'"></font-awesome-icon>
          <font-awesome-icon :icon="['fal', 'utensils']" size="2x" color="#111111" v-else></font-awesome-icon>
        </router-link>
        <router-link to="/activities" v-if="this.account.type != 'family'">
          <font-awesome-icon :icon="['fas', 'walking']" size="2x" color="#111111" v-if="currentRoute == 'activities'"></font-awesome-icon>
          <font-awesome-icon :icon="['fal', 'walking']" size="2x" color="#111111" v-else></font-awesome-icon>
        </router-link>
        <router-link to="/news" v-if="this.account.type != 'family'">
          <font-awesome-icon :icon="['fas', 'newspaper']" size="2x" color="#111111" v-if="currentRoute == 'news'"></font-awesome-icon>
          <font-awesome-icon :icon="['fal', 'newspaper']" size="2x" color="#111111" v-else></font-awesome-icon>
        </router-link>
        <router-link to="/settings">
          <font-awesome-icon :icon="['fas', 'cog']" size="2x" color="#111111" v-if="currentRoute == 'settings'"></font-awesome-icon>
          <font-awesome-icon :icon="['fal', 'cog']" size="2x" color="#111111" v-else></font-awesome-icon>
        </router-link>
    </footer>
</template>

<script>
import store from '../store'

export default {
    data() {
        return {
            currentRoute: 'home',
            account: {},
        }
    },
    mounted() {
      this.currentRoute = this.$router.currentRoute.name
      
      this.account = store.state.accountForActiveResident
    },
    methods: {
    },
    computed: {
        isLoggedIn() {
            return this.$store.getters.isLoggedIn
        },
    },
    watch: {
        $route (to, from) {
            this.currentRoute = this.$router.currentRoute.name
        },
    }
}
</script>

<style scoped>
.fp-footer {
    display: flex;
    align-items: center;
    justify-content: space-around;
    position: fixed;
    bottom: 0px;
    height: 60px;
    left: 0px;
    right: 0px;
    overflow: hidden;
    padding-bottom: 10px;
    background-color: white;
    box-shadow: 
        0 4px 2px 0 rgba(0, 0, 0, 0.14),
        0 3px 1px -2px rgba(0, 0, 0, 0.12),
        0 1px 9px 0 rgba(0, 0, 0, 0.2);
}
</style>
