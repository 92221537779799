<template>
  <div class="row before-upload-view">
        <div class="row" style="height: 60%; display: flex; align-items: center; justify-content: center; background-color: black;">
            <!-- video or image -->
            <img id="photo" v-if="mediaType == 0" :src="this.mediaBase64" class="photo" alt="Your picture" />
            <span @click="rotateImage" v-if="mediaType == 0" style="cursor: pointer; color: white; position: absolute; top: 10px; right: 10px;"><i data-v-21f0c290="" class="material-icons left">rotate_right</i></span>
            <video id="video" v-if="mediaType == 1" :src="this.mediaBase64" controls class="video" alt="Your video"></video>
        </div>
        <div class="row">
            <form class="col s12">
                <div class="row" style="margin-bottom: 0;">
                    <div class="input-field col s12" style="margin-bottom: 0;" @click="setFocusTextArea()">
                        <i class="material-icons prefix">mode_edit</i>
                        <textarea v-model="caption" class="materialize-textarea" ref="caption"></textarea>
                        <label for="textarea1">{{ $t('beforeUpload.caption') }}</label>
                    </div>
                </div>
                <div class="row" style="margin-bottom: 0;">
                    <div class="input-field col s12" style="margin-top: 0;">
                        <select v-model="privacy" class="browser-default">
                            <option value="1">{{ $t('beforeUpload.visibleResident', { name: resident.fname + ' ' + resident.lname}) }}</option>
                            <option value="2">{{ $t('beforeUpload.visibleFamily') }}</option>
                        </select>
                    </div>
                </div>
            </form>
        </div>
        <div class="row">
            <div style="display: flex; align-items: center; justify-content: center;">
                <a @click.prevent="cancelUpload" class="btn waves-effect waves-light red" style="margin-right: 10px">
                    <i class="material-icons left">cancel</i>
                    {{ $t('beforeUpload.cancel') }}
                </a>
                <a v-if="!uploadActive" @click.prevent="uploadPhoto" class="btn waves-effect waves-light green">
                    <i class="material-icons left">check</i>
                    {{ $t('beforeUpload.upload') }}
                </a>
                <a v-else class="btn waves-effect waves-light orange">
                    <i class="material-icons left">cloud_upload</i>
                    Uploading... ({{ percentCompleted }} %)
                </a>
            </div>
        </div>
  </div>
</template>

<script>
import swal from 'sweetalert'
import store from '../store'
import imagetools from '../helpers/ImageTools'

export default {
    name: 'BeforeUpload',
    props: {
      media: {
      },
      camera: {
          type: Boolean,
          default: false
      }  
    },
    data() {
        return {
            resident: {},
            caption: '',
            privacy: 1,
            mediaBase64: '',
            mediaType: null, // 0 for image & 1 for video
            mediaResized: null,
            uploadActive: false,
            percentCompleted: 0,
            settings: JSON.parse(this.$store.state.user.settings),
        }
    },
    destroyed() {
        this.$emit('deactivatefullscreen')
    },
    mounted() {

        this.$emit('activatefullscreen')

        let that = this

        this.resident = store.state.residents[store.state.activeResident]
        this.user = store.state.user

        navigator.serviceWorker.onmessage = function(event) {
            const imageBlob = event.data.image
        }

        if(this.settings.posts.defaultSharedValue)
        {
            this.privacy = 2
        }

        if(this.media == undefined){
            this.$router.go(-1)
        } else {
            // determine type
            if(this.media.type.startsWith('image')) {
                this.mediaType = 0
                if(!this.camera)
                {
                    console.log(this.media)
                    this.sendImageToResize(this.media)
                        .then(() => {
                            let reader = new FileReader()
                            reader.readAsDataURL(this.mediaResized)
                            reader.onload = e => {
                                this.mediaBase64 = e.target.result
                            }
                        })
                } else {
                    this.mediaBase64 = this.media.data
                    // console.log(this.media)
                    // let reader = new FileReader()
                    // reader.readAsDataURL(this.media)
                    // reader.onload = e => {
                    //     console.log(e.target.result)
                    //     this.mediaBase64 = e.target.result
                    // }
                    // this.mediaBase64 = this.media
                }
            }

            if(this.media.type.startsWith('video')) {
                this.mediaType = 1

                let reader = new FileReader()
                reader.readAsDataURL(this.media)
                reader.onload = e => {
                    // console.log(e.target.result)
                    this.mediaBase64 = e.target.result
                }
            }
        }
    },
    methods: {
        rotateImage() {
            this.mediaBase64 = this.rotateBase64Image90deg(this.mediaBase64, true)
        },
        rotateBase64Image90deg(base64Image, isClockwise) {
            // create an off-screen canvas
            let offScreenCanvas = document.createElement('canvas');
            let offScreenCanvasCtx = offScreenCanvas.getContext('2d');

            // cteate Image
            var img = new Image();
            img.src = base64Image;

            // set its dimension to rotated size
            offScreenCanvas.height = img.width;
            offScreenCanvas.width = img.height;

            // rotate and draw source image into the off-screen canvas:
            if (isClockwise) { 
                offScreenCanvasCtx.rotate(90 * Math.PI / 180);
                offScreenCanvasCtx.translate(0, -offScreenCanvas.width);
            } else {
                offScreenCanvasCtx.rotate(-90 * Math.PI / 180);
                offScreenCanvasCtx.translate(-offScreenCanvas.height, 0);
            }
            offScreenCanvasCtx.drawImage(img, 0, 0);

            // encode image to data-uri with base64
            return offScreenCanvas.toDataURL("image/jpeg", 100);
        },
        sendImageToResize(image) {
            let that = this
            return new Promise((resolve, reject) => {
                imagetools.resize(image, {
                    width: 1280,
                    height: 720
                }, function(blob, didItResize) {
                    if(didItResize)
                    {
                        that.mediaResized = new File([blob], that.media.name, {
                            type: that.media.type
                        })
                        resolve()
                    }
                    that.mediaResized = new File([blob], that.media.name, {
                        type: that.media.type
                    })
                    resolve()
                })
            })
        },
        setFocusTextArea() {
            this.$refs.caption.focus()
        },
        cancelUpload() {
            this.$router.push('/')
        },
        uploadPhoto() {
            this.uploadActive = true
            // const mediaStreamTrack = this.mediaStream.getVideoTracks()[0]
            // const imageCapture = new window.ImageCapture(mediaStreamTrack)
            // return imageCapture.takePhoto().then(blob => {
            let form = new FormData()
            form.append("blob", this.mediaBase64)
            form.append("media_type", this.mediaType)
            form.append("resident_id", this.resident.id)
            form.append("caption", this.caption)
            form.append("privacy", this.privacy)
            axios.post(process.env.VUE_APP_SERVER_URL + '/api/create-new-post', form, {
                headers: {
                    'Authorization': 'Bearer ' + store.state.auth.auth_token
                },
                onUploadProgress: function( progressEvent ) {
                    this.percentCompleted = Math.round( (progressEvent.loaded * 100) / progressEvent.total )
                }.bind(this)
            }).then((response) => {
                swal(this.$t('beforeUpload.done'), this.$t('beforeUpload.doneCaption'), 'success').then((value) => {
                    store.dispatch('fetchActiveResidentPosts').then(() => this.$router.push('/'))
                })
            }).catch((error) => {
                // show an error message
                console.error(error)
                swal(this.$t('beforeUpload.error'), this.$t('beforeUpload.errorCaption'), 'error')
            })
            // })
        },
    },
}
</script>

<style scoped>
.before-upload-view {
    position: fixed;
    top: 0;
    height: 90%;
    background-color: white;
    overflow-y: scroll;
    overflow-x: hidden;
    width: 100%;
}

.row {
    margin-bottom: 0px;
}

.photo {
    max-width: 100%;
    max-height: 100%;
}

.video {
    max-width: 100%;
    max-height: 100%;
}

.input-field {
    display: block !important;
}

</style>
