<template>
    <div class="row camera-view">
        <!-- this needs improvement -->
        <div v-show="showVideo" class="camera-modal">
            <video ref="video" id="video" class="camera-stream" autoplay playsinline>Video stream not available.</video>
            <div class="camera-modal-container">
                <span @click="cancelPhoto" class="btn-floating btn-large waves-effect waves-light orange">
                    <i class="material-icons" style="line-height: 56px !important;">close</i>
                </span>
                <span @click="capture" class="btn-floating btn-large waves-effect waves-light red take-picture-button">
                    <i class="material-icons">camera</i>
                </span>
                <span @click="switchCamera" v-show="multipleCamera" class="btn-floating btn-large waves-effect waves-light green switch-camera-button">
                    <i class="material-icons" style="font-size: 35px; line-height: 1.6em">switch_camera</i>
                </span>
            </div>
        </div>
        <canvas id="canvas"></canvas>
        <div v-show="showPhoto" class="output">
            <img id="photo" alt="Your picture" />
            <div style="display: flex; align-items: center; justify-content: center;">
                <a @click.prevent="cancelPhoto" class="btn waves-effect waves-light red" style="margin-right: 10px">
                    <i class="material-icons left">cancel</i>
                    Cancel
                </a>
                <a @click.prevent="uploadPhoto" class="btn waves-effect waves-light green">
                    <i class="material-icons left">check</i>
                    Upload
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import swal from 'sweetalert'

export default {
    data () {
        return {
            showVideo: true,
            showPhoto: false,
            width: window.innerWidth,
            height: 0,
            video: null,
            canvas: null,
            photo: null,
            startbutton: null,
            mediaStream: undefined,
            multipleCamera: false,
            videoDevices: null,
            activeVideoDevice: null,
        }
    },
    destroyed() {
        this.$emit('deactivatefullscreen')
    },
    mounted () {

        this.$emit('activatefullscreen')

        this.video = document.getElementById('video')
        navigator.mediaDevices.enumerateDevices().then(this.gotDevices).catch(this.handleError)

        this.canvas = document.getElementById('canvas')
        this.photo = document.getElementById('photo')
    },
    methods: {
        cancelPhoto() {
            this.photo.setAttribute('src', null)
            this.showPhoto = false
            this.showVideo = true
            this.$emit('deactivatefullscreen')
            this.$router.push({ name: 'home' })
        },
        // sendToBeforeUpload() {
        //     console.log('no way')
        //     this.$router.push({ name: 'before-upload', params: { media: this.photo.getAttribute('src') } })
        // },
        // uploadPhoto() {
        //     console.log('will now upload your stupid photo')
        //     // const mediaStreamTrack = this.mediaStream.getVideoTracks()[0]
        //     // const imageCapture = new window.ImageCapture(mediaStreamTrack)
        //     // return imageCapture.takePhoto().then(blob => {
        //         let form = new FormData()
        //         form.append("blob", this.photo.getAttribute('src'))
        //         form.append("resident_id", this.$store.state.local_db.residents[0].id)
        //         axios.post(process.env.VUE_APP_SERVER_URL + '/api/new-media', form, {
        //             headers: {
        //                 'Authorization': 'Bearer ' + localStorage.access_token
        //             }
        //         }).then((response) => {
        //             swal('Helemaal klaar!', 'Uw foto werd opgeladen!', 'success').then((value) => {
        //                 this.$router.push('/')
        //             })
        //         }).catch((error) => {
        //             // show an error message
        //             console.error(error)
        //             swal('Error!', 'Uw foto kon niet opgeladen worden door een fout op de server!', 'error')
        //         })
        //     // })
        // },
        capture () {
            let context = this.canvas.getContext('2d')
            let height = this.video.videoHeight / (this.video.videoWidth/this.width)
            context.drawImage(video, 0, 0, this.width, height)
            context.drawImage(video, 0, 0, this.width, this.height)
            let data = this.canvas.toDataURL('image/png')


            // let data = new File([this.canvas.toDataURL('image/png')], 'media', {
            //     type: 'image/png'
            // })

            const tracks = this.mediaStream.getTracks()
            tracks.map(track => track.stop())

            this.$router.push({ name: 'before-upload', params: { media: {
                type: 'image',
                data
            }, camera: true } })
            // this.photo.setAttribute('src', data)
            // this.showVideo = false
            // this.showPhoto = true
        },
        stopMediaTracks (stream) {
            stream.getTracks().forEach(track => {
                track.stop()
            })
        },
        setCamera (videoSource) {
            if(typeof this.mediaStream !== 'undefined') {
                this.stopMediaTracks(this.mediaStream)
            }
            console.log(`now setting the camera to what we get here in our argument being ${videoSource}`)
            navigator.mediaDevices.getUserMedia({ video: { deviceId: videoSource } })
                .then(mediaStream => {
                    console.log(mediaStream)
                    this.activeVideoDevice = videoSource
                    this.mediaStream = mediaStream
                    this.$refs.video.srcObject = mediaStream
                    //this.$refs.video.play()
                    // set width and height of canvas element
                    let that = this
                    this.$refs.video.addEventListener('canplay', (ev) => {
                        this.height= this.$refs.video.videoHeight / (this.$refs.video.videoWidth/this.width)
                        this.canvas.setAttribute('width', this.width)
                        this.canvas.setAttribute('height', this.height)
                    })
                })
                .catch(error => this.handleError(error))
        },
        handleError (error) {
            console.error('navigator.getUserMedia error: ', error)
        },
        switchCamera () {
            // find current activated device in this.videoDevices
            const activeVideoDeviceIndex = this.videoDevices.findIndex((videodevice) => {
                return videodevice.deviceId == this.activeVideoDevice
            })
            if(activeVideoDeviceIndex + 1 >= this.videoDevices.length) {
                this.setCamera(this.videoDevices[0].deviceId)
            } else {
                this.setCamera(this.videoDevices[activeVideoDeviceIndex + 1].deviceId)
            }
        },
        gotDevices (devicesInfo) {
            this.videoDevices = devicesInfo.filter((device) => {
                return device.kind == 'videoinput'
            })
            console.dir(this.videoDevices)
            if(this.videoDevices.length > 0) {
                // bind stream of first camera to video element
                this.setCamera(this.videoDevices[0].deviceId)
                if(this.videoDevices.length > 1) {
                    // show switch camera button
                    this.multipleCamera = true
                }
            } else {
                console.error('We could not find a Camera on your device')
            }
        }
    },
    destroyed () {
        const tracks = this.mediaStream.getTracks()
        tracks.map(track => track.stop())
    }
    
}
</script>

<style scoped>

.camera-view {
    position: fixed;
    top: 0px;
    /* height: 100%; */
    background-color: white;
}

.camera-modal {
    width: 100%;
    height: 100%;
    /* top: 0;
    left: 0;
    position: absolute; */
    background-color: white;
    z-index: 10;
}
.camera-stream {
    width: 100%;
    max-height: 100%;
}

.camera-modal-container {
    display: flex;
    align-items: center;
    justify-content: space-around;
    position: fixed;
    bottom: 60px;
    height: 100px;
    left: 0px;
    right: 0px;
    overflow: hidden;
    padding-bottom: 10px;
    background-color: white;
}

.take-picture-button {
    cursor: pointer;
    height: 80px !important;
    width: 80px !important;
}

.switch-camera-button {
    cursor: pointer;
    font-size: 10px;
    line-height: 1.5rem;
}

.switch-camera-button i {
    font-size: 3rem;
}

.btn-floating.btn-large i {
    font-size: 3rem;
    line-height: 80px;
}

#canvas {
    display: none;
}

</style>