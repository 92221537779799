<template>
  <div class="container">
    <div class="row center-align">
      <img src="../assets/logo.png" style="max-height: 100px;">
    </div>
    <div class="row center-align" id="pincode">
      <h3>{{ $t('linkResident.greeting', { name: userName() }) }}</h3>
      <p>{{ $t('linkResident.instructions') }}</p>
      <div id="fields" v-if="!checkCodeLoading">
        <div class="grid" id="fields-wrapper">
          <div class="grid__col grid__col--1-of-6 numberfield"><span v-html="userCodeChar(0)"></span></div>
          <div class="grid__col grid__col--1-of-6 numberfield"><span v-html="userCodeChar(1)"></span></div>
          <div class="grid__col grid__col--1-of-6 numberfield"><span v-html="userCodeChar(2)"></span></div>
          <div class="grid__col grid__col--1-of-6 numberfield"><span v-html="userCodeChar(3)"></span></div>
          <div class="grid__col grid__col--1-of-6 numberfield"><span v-html="userCodeChar(4)"></span></div>
          <div class="grid__col grid__col--1-of-6 numberfield"><span v-html="userCodeChar(5)"></span></div>
        </div>
      </div>
      <div id="loading" v-if="checkCodeLoading"></div>

      <div id="numbers">
        <div class="grid">
          <div class="grid__col grid__col--1-of-3"><button @click="enterNumber(1)">1</button></div>
          <div class="grid__col grid__col--1-of-3"><button @click="enterNumber(2)">2</button></div>
          <div class="grid__col grid__col--1-of-3"><button @click="enterNumber(3)">3</button></div>
          <div class="grid__col grid__col--1-of-3"><button @click="enterNumber(4)">4</button></div>
          <div class="grid__col grid__col--1-of-3"><button @click="enterNumber(5)">5</button></div>
          <div class="grid__col grid__col--1-of-3"><button @click="enterNumber(6)">6</button></div>
          <div class="grid__col grid__col--1-of-3"><button @click="enterNumber(7)">7</button></div>
          <div class="grid__col grid__col--1-of-3"><button @click="enterNumber(8)">8</button></div>
          <div class="grid__col grid__col--1-of-3"><button @click="enterNumber(9)">9</button></div>
          <div class="grid__col grid__col--1-of-3"></div>
          <div class="grid__col grid__col--1-of-3"><button @click="enterNumber(0)">0</button></div>
          <div class="grid__col grid__col--1-of-3"></div>
        </div>
      </div>
    </div>
    <div class="row" style="text-align: center">
      <router-link v-if="hasResidents" to="/">{{ $t('linkResident.cancel') }}</router-link>
      <a v-if="!hasResidents" @click="logout()">{{ $t('settings.logout') }}</a>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import swal from "sweetalert"
import store from "../store"

export default {
  components: {
  },
  name: "link-resident",
  data() {
    return {
      checkCodeLoading: false,
      userCode: '',
      hasResidents: this.$store.state.hasResidents,
      user: this.$store.state.user
    };
  },
  created() {
  },
  destroyed() {
      this.$emit('deactivatefullscreen')
  },
  mounted() {
      this.$emit('activatefullscreen')
  },
  methods: {
    userCodeChar(i) {
      return this.userCode.split('')[i]
    },
    enterNumber(number) {
      if(this.userCode.length > 5) { return }
      this.userCode = this.userCode + number
      let length = this.userCode.length
      length--
      let fields = document.getElementById('fields-wrapper')
      let currentFieldSpan = fields.childNodes[length]
      currentFieldSpan.classList.add('active')
      if(length > 4) {
        this.checkCodeLoading = true
        axios.post(`${process.env.VUE_APP_SERVER_URL}/api/validate-sync-code`, { code: this.userCode, user: this.user }, {
          headers: {
              'Authorization': 'Bearer ' + store.state.auth.auth_token
          }
        })
          .then((response) => {
            if(response.status == '404') {
              // this should never happen because Laravel throws a 404 because we use findOrFail
              // javascript goes to the catch block
              // if you are 100% sure this is correct you can delete this if statement...
              console.log('Sorry could not find this resident')
            } else {
              this.checkCodeLoading = false
              this.userCode = ''
              // we should let the store make sure we now have a resident
              this.$store.commit('TOGGLE_KEY_HAS_RESIDENTS', true)
              this.$store.dispatch('fetchLinkedResidents').then(() => {
                this.$store.dispatch('fetchActiveResidentAccount').then(() => {
                  this.$store.dispatch('fetchActiveResidentPosts').then(() => {
                    this.$router.push('/')
                  })
                })
              })
            }
          }).catch((error) => {
            console.error('We couldnt even check because of this error.', error)
            this.checkCodeLoading = false
            this.userCode = ''
          })
      }
    },
    userName() {
      // check if we have a user name and return it otherwhise just say hello
      return this.user.fname
    },
    checkUniqueCode() {
      console.log('check it out');
    },
    logout() {
        this.$store.dispatch('logout')
        this.$router.push('/login')
    },
  }
};
</script>

<style scoped>

#fields {
  max-width: 300px;
  padding: 0 20px;
  margin: 30px auto;
  position: relative;
  display: block;
}

#fields .numberfield {
  text-align: center;
}

#fields .numberfield span {
  height: 20px;
  width: 20px;
  border: 2px solid #000;
  background-color: transparent;
  border-radius: 100%;
  position: relative;
  display: inline-block;
  text-align: center;
}

#fields .numberfield.active span {
  height: 20px;
  width: 20px;
  border: 0;
  border-radius: 0%;
  position: relative;
  display: inline-block;
  text-align: center;
  font-size: 20px;
  font-weight: 500;
}

#fields .numberfield.right span {
  background-color: #272a2f;
  border-color: #272a2f;
  transition: all .5s ease-in-out;
  -webkit-transition: all .5s ease-in-out;
}

#numbers {
  max-width: 300px;
  padding: 0 20px;
  margin: 0 auto;
  position: relative;
  display: block;
  -webkit-transition: all 1s ease-out;
  -moz-transition: all 1s ease-out;
  transition: all 1s ease-out;
  opacity: 1;
}

#numbers.hide {
  opacity: 0.3;
}

#pincode button {
  width: 70px;
  height: 70px;
  margin-bottom: 10px;
  background-color: rgba(255, 255, 255, 0.45);
  border: 0;
  color: #000;
  font-size: 25px;
  line-height: 50px;
  border-radius: 100%;
  opacity: 1;
  outline: 0;
  border: 2px solid #272a2f;
}

#pincode button:active {
  background-color: rgba(0, 0, 0, 0.6);
  outline: 0;
}

#loading {
  display: inline-block;
  margin: 10px auto;
  width: 50px;
  height: 50px;
  border: 3px solid rgba(0,0,0,.3);
  border-radius: 50%;
  border-top-color: #fff;
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to { -webkit-transform: rotate(360deg); }
}
@-webkit-keyframes spin {
  to { -webkit-transform: rotate(360deg); }
}

/*------
# Toast Grid
----*/

.grid {
  list-style: none;
  margin-left: -20px;
}

.grid__col--1-of-3,
.grid__col--2-of-6,
.grid__col--4-of-12 {
  width: 33.33333%;
}

.grid__col--1-of-4,
.grid__col--2-of-8,
.grid__col--3-of-12 {
  width: 25%;
}

.grid__col {
  box-sizing: border-box;
  display: inline-block;
  margin-right: -.25em;
  min-height: 1px;
  padding-left: 20px;
  vertical-align: top;
}

.grid__col--centered {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.grid__col--d-first {
  float: left;
}

.grid__col--d-last {
  float: right;
}

.grid--no-gutter {
  margin-left: 0;
  width: 100%;
}

.grid--no-gutter .grid__col {
  padding-left: 0;
}

.grid--no-gutter .grid__col--span-all {
  margin-left: 0;
  width: 100%;
}

.grid__col--ab {
  vertical-align: bottom;
}

.grid__col--am {
  vertical-align: middle;
}

</style>