<template>
    <div class="videocall-wrapper">
        <div id="localVideoTrack" :class="[{ localVideoFullScreen: isCalling }, { localVideoSmallScreen: !isCalling }]">

        </div>

        <div id="remoteVideoTrack" class="remoteVideoTrack">
        </div>


        <div class="camera-modal-container">
            <span @click="endVideoCall" class="btn-floating btn-large waves-effect waves-light orange">
                <i class="material-icons" style="line-height: 56px !important;">close</i>
            </span>
            <!-- <span @click="switchCamera" v-show="multipleCamera" class="btn-floating btn-large waves-effect waves-light green switch-camera-button">
                <i class="material-icons" style="font-size: 35px; line-height: 1.6em">switch_camera</i>
            </span> -->
        </div>
    </div>
</template>

<script>

import store from '../../store'
import Twilio, { connect, createLocalTracks, createLocalVideoTrack } from 'twilio-video'

export default {
    // we create the room and connect ourselves as client

    data() {
        return {
            username: '',
            room_name: '',
            room: null,
            connection_status: 'calling'
        }
    },

    mounted() {

        // emit event to let the parent know that we are in fullscreen modus
        this.$emit('activatefullscreen')

        this.username = `${store.state.user.fname} ${store.state.user.lname}`
        this.room_name = `${store.state.user.fname}_calling_to_${store.state.residents[store.state.activeResident].fname}_${Math.random().toString(36).substring(2,9)}`

        this.createVideoRoom()

    },

    computed: {
        isCalling() {
            return this.connection_status == 'calling'
        }
    },
    
    destroyed() {
        console.log('will properly leave the room. this should automagically free up tracks')
        this.leaveRoomIfJoined()

        this.$emit('deactivatefullscreen')
    },

    methods: {

        endVideoCall() {
            console.log('please terminat the call')
            // let the server know that user x terminated the call to resident y
            let callOptions = {
                user: store.state.user,
                resident: store.state.residents[store.state.activeResident],
                roomInformation: this.room
            }

            axios.post(process.env.VUE_APP_SERVER_URL + '/api/user-ended-videochat', callOptions, {
                headers: {
                    'Authorization': 'Bearer ' + this.$store.state.auth.auth_token
                }
            }).then((res) => {
                console.log(res)
            }).catch((err) => {
                console.error(err)
            })


        },

        async getAccessToken() {
            return await axios.get(process.env.VUE_APP_SERVER_URL + `/api/videochat/access-token?identity=${this.username}&room_name=${this.room_name}`, {
                headers: {
                    'Authorization': 'Bearer ' + this.$store.state.auth.auth_token
                }
            })
        },

        leaveRoomIfJoined() {
            if(this.room) {
                this.room.disconnect()
                this.room.localParticipant.tracks.forEach(track => console.log(track.isStopped))
            }
        },

        attachTracks(tracks, containter) {
            tracks.forEach((track) => {
                container.appendChild(track.attach())
            })
        },

        attachParticipantTracks(participant, container) {
            let tracks = Array.from(participant.tracks.values())
            this.attachTracks(tracks, container)
        },

        detachTracks(tracks) {
            tracks.forEach((track) => {
                track.detach().forEach((detachedElement) => {
                    detachedElement.remove()
                })
            })
        },

        detachParticipantTracks(participant) {
            let tracks = Array.from(participant.tracks.values())
            this.detachTracks(tracks)
        },

        createVideoRoom() {
            this.getAccessToken().then(({data}) => {
                const token = data.token
                let connectOptions = {
                    name: this.room_name,
                    audio: true,
                    maxAudioBitrate: 16000, //For music remove this line
                    video: { width: 1280, height: 720, frameRate: 24 },
                    logLevel: 'debug'
                }

                Twilio.connect(token, connectOptions).then((room) => {
                    this.room = room

                    const localVideoTrack = Array.from(room.localParticipant.videoTracks.values())[0].track
                    let localMediaContainer = document.getElementById('localVideoTrack')
                    localMediaContainer.appendChild(localVideoTrack.attach())

                    // createLocalVideoTrack().then((track => {
                    //     let localMediaContainer = document.getElementById('localVideoTrack')
                    //     localMediaContainer.appendChild(track.attach())
                    // }))

                    // room.participants.forEach((participant) => {
                    //     let previewContainer = document.getElementById('remoteVideoTrack')
                    //     this.attachParticipantTracks(participant, previewContainer)
                    // })

                    room.on('participantConnected', (participant) => {
                        console.log(`A remote participant connectect: ${participant}`)
                        this.connection_status = 'connected'
                        let remoteMediaContainer = document.getElementById('remoteVideoTrack')

                        participant.tracks.forEach(publication => {
                            if(publication.isSubscribed) {
                                const track = publication.track
                                remoteMediaContainer.appendChild(track.attach())
                            }
                        })

                        participant.on('trackSubscribed', track => {
                            remoteMediaContainer.appendChild(track.attach())
                        })
                    })

                    // room.on('trackAdded', (track, participant) => {
                    //     // VueThis.$emit('newlog', participant.identity + 'enabled ' + track.kind)
                    //     let previewContainer = document.getElementById('remoteVideoTrack')
                    //     this.attachTracks([track], previewContainer)
                    // })

                    // room.on('trackRemoved', (track, participant) => {
                    //     // VueThis.$emit('newlog', participant.identity + ' disabled ' + track.kind)
                    //     this.detachTracks([track])
                    //     // console.log('detach participant track from the DOM')
                    // })

                    // room.on('participantDisconnected', (participant) => {
                    //     // VueThis.$emit('newlog', participant.identity + ' left the room')
                    //     this.detachParticipantTracks(participant)
                    // })

                    // let the server know that user x wants to call resident y
                    let callOptions = {
                        user: store.state.user,
                        resident: store.state.residents[store.state.activeResident],
                        roomInformation: this.room
                    }

                    axios.post(process.env.VUE_APP_SERVER_URL + '/api/user-started-new-videochat', callOptions, {
                        headers: {
                            'Authorization': 'Bearer ' + this.$store.state.auth.auth_token
                        }
                    }).then((res) => {
                        console.log(res)
                    }).catch((err) => {
                        console.error(err)
                    })

                })

            })
        }

    }

}
</script>

<style>

.videocall-wrapper {
    position: fixed;
    top: 0;
    height: 100%;
    background-color: white;
    overflow-y: scroll;
    overflow-x: hidden;
    width: 100%;
}

.localVideoFullScreen {
    position: relative;
    width: 100%;
    height: 100%;
}

.localVideoSmallScreen {
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 30%;
    height: 25%;
}

.localVideoFullScreen video {
    height: 100%;
    width: 100%;
    object-fit: fill; /*magic*/
}

.localVideoSmallScreen video {
    height: 100%;
    width: 100%;
    object-fit: fill; /*magic*/
}

.localVideoTrack {
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 50%;
}



</style>