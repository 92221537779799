<template>
  <div class="row add-media-wrapper">
    <div class="center-align">
      <router-link to="/camera" class="btn-floating btn-large waves-effect waves-light red"><i class="material-icons">camera_alt</i></router-link>
      <!-- <button v-if="!cameraSupported" class="btn-floating btn-large waves-effect waves-light grey"><i class="material-icons">camera_alt</i></button> -->
      <!-- <router-link v-if="cameraSupported && !accessToCamera" to="/camera" class="btn-floating btn-large waves-effect waves-light grey"><i class="material-icons">perm_camera_mic</i></router-link> -->
    </div>
    <div class="center-align" v-if="videocallers.includes(user.email)">
      <router-link to="/video-call" class="btn-floating btn-large waves-effect waves-light red"><i class="material-icons">duo</i></router-link>
    </div>
    <div class="center-align">
      <router-link to="/text-message" class="btn-floating btn-large waves-effect waves-light red"><i class="material-icons">message</i></router-link>
    </div>
    <div class="center-align">
      <input type="file" id="uploadMedia" accept="image/*,video/*" style="display: none;" @change="mediaSelected($event)">
      <button class="btn-floating btn-large waves-effect waves-light red" @click="simulateFormClick($event)"><i class="material-icons">sd_storage</i></button>
    </div>
  </div>
</template>

<script>
import store from '../store'

export default {
  name: 'AddMedia',
  data() {
    return {
      cameraSupported: false,
      accessToCamera: false,
      mediaStream: undefined,
      user: store.state.user,
      videocallers: [
        'sleemans.screenservices@gmail.com',
        'dante.meulemeester@gmail.com',
        'dante@concept-it.be',
        'neco@voo.be'
      ]
    }
  },
  mounted() {
    // see if we can access the camera via the new mediadevices api.
    // if not we can only allow to upload images via the classical way
    // if(navigator.mediaDevices === undefined) {
    //   navigator.mediaDevices = {}
    // }

    // if(navigator.mediaDevices.getUserMedia === undefined) {
    //   navigator.mediaDevices.getUserMedia = function(constraints) {
    //     let getUserMedia = navigator.webkitGetUserMedia || navigator.mozGetUserMedia
        
    //     if(!getUserMedia) {
    //       return Promise.reject(new Error('getUserMedia is not implemented in this browser'))
    //     }
        
    //     return new Promise(function(resolve, reject) {
    //       getUserMedia.call(navigator, constraints, resolve, reject)
    //     })
    //   }
    // }

    // navigator.mediaDevices.getUserMedia({ video: { width: 1280 }}).then((stream) => {
    //   this.mediaStream = stream
    //   // console.log('look at that we have access to the video')
    //   this.cameraSupported = true
    //   this.accessToCamera = true
    // }).then(() => {
    //   // stop all tracks we just needed to know if video was supported
    //   const tracks = this.mediaStream.getTracks()
    //   tracks.map(track => track.stop())
    // }).catch((err) => {
    //   if(err.message.toLowerCase().indexOf('permission denied') == 0) {
    //     console.error('Sorry you do not have permission to access the camera')
    //     this.cameraSupported = true
    //     this.accessToCamera = false
    //   } else {
    //     console.error(err)
    //     console.error('Seems like this device does not support Camera via navigator.mediaDevices')
    //     this.cameraSupported = false
    //   }
    // })


  },
  methods: {
    simulateFormClick(e) {
      document.getElementById('uploadMedia').click()
    },
    mediaSelected(e) {
      if (! e.target.files.length) return

      // you may not upload files bigger then 40M
      if(((e.target.files[0].size/1024)/1024).toFixed(4) > 40) {
        swal('Error!', 'Dit bestand is groter dan 40MB! Verklein het via cloudconvert.com', 'error')
        return
      }

      let file = e.target.files[0]
      this.$router.push({ name: 'before-upload', params: { media: file } })
    }
  },
  destroyed () {
    if(this.mediaStream) {
      const tracks = this.mediaStream.getTracks()
      tracks.map(track => track.stop())
    }
  }
}
</script>

<style scoped>
  .btn-floating.btn-large {
    width: 80px !important;
    height: 80px !important;
  }

  .btn-floating.btn-large i {
    line-height: 80px !important;
  }

  .btn-large i {
    font-size: 4rem !important;
  }

  .add-media-wrapper {
    display: flex;
    justify-content: space-evenly;
  }
</style>
