<template>
    <div class="container">
        easy add invitation (name and email)
        share button

        list active invitations

        list active users and possibility to toggle admin status
    </div>

</template>

<script>
export default {

}
</script>

<style>

</style>