<template>
  <div class="row">
    <div v-if="posts.length > 0">
        <div class="grid-container">
            <div class="news-wrapper card card-border z-depth-1" v-for="post in posts" :key="post.id">
                <div class="row">
                    <div class="col s2 circle" style="margin: 8px;">
                        <a href="#"><img class="responsive-img circle" :src="avatarUrl(post.user.media[0])" alt=""></a>
                    </div>
                    <div class="col s8">
                        <div class="row author-name">
                            {{ post.user.fname + ' ' + post.user.lname }}
                        </div>
                        <div class="row time-indication">
                            <font-awesome-icon icon="clock" />
                            <span> {{ formattedDate(post.created_at) }} </span>
                        </div>
                    </div>
                    <div class="col s1">
                        <div class="actions" style="padding: 8px;" v-if="isOwner(post.user_id)">
                            <font-awesome-icon @click="confirmDelete(post.id)" style="cursor: pointer;" icon="trash" transform="down-3" />
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col s12">
                        <div v-if="post.has_media !== 0">
                            <div v-if="post.media_status == 'ready'">
                                <img v-if="post.media[0].mime_type.startsWith('image')" :src="imageUrl(post.media[0])" alt="">
                                <video v-if="post.media[0].mime_type.startsWith('video')" :src="imageUrl(post.media[0])" controls="true" alt="Video"></video>
                            </div>
                            <div v-else>
                                <img height="128px" src="../assets/loading.gif" />
                                <p class="center-align">{{ $t('newsFeed.preparingMedia') }}</p>
                            </div>
                        </div>
                        <div v-else>
                        </div>
                        <p>{{ post.caption }}</p>
                    </div>
                </div>
                <div v-if="fakerDemo.includes(user.email)">
                    <comments></comments>
                </div>
            </div>
            <div style="display: flex; justify-content: center;">
                <a v-if="showLoadMorePosts" class="waves-effect waves-light btn" @click="loadMorePosts()"><i class="material-icons right">more_horiz</i>Meer bijdragen.</a>
                <a v-else>Dit is het einde van de bijdragen.</a>
            </div>
        </div>
    </div>
    <div class="container" v-else>
        <!-- show component to encourage posting things -->
        <div>
            {{ $t('newsFeed.noPosts') }}
        </div>
        <!-- if family admin user also encourage to invite others! -->
        <div v-if="user.group == 'family-admin-user'">
            Klik hier om andere familieleden uit te nodigen om deel te nemen!
        </div>
    </div>

    <!-- Modal Structure -->
    <div id="confirm-delete" class="modal bottom-sheet" style="margin-bottom: 60px !important;">
        <div class="modal-content">
            <h4>{{ $t('newsFeed.titleDelete') }}</h4>
            <ul class="collection">
                <li class="collection-item modal-option" @click="closeModalConfirmDelete">
                    <font-awesome-icon style="cursor: pointer;" icon="times" />
                    {{ $t('newsFeed.cancelDelete') }}
                </li>
                <li class="collection-item modal-option modal-option-confirm" @click="confirmedDelete">
                    <font-awesome-icon style="cursor: pointer;" icon="trash" />
                    {{ $t('newsFeed.confirmDelete') }}
                </li>
            </ul>
        </div>
    </div>

  </div>
</template>

<script>
import { differenceInBusinessDays, format } from 'date-fns'
import store from '../store'
import Comments from './Comments'
import debounce from 'lodash/debounce'

export default {
    components: {
        Comments
    },
    name: 'NewsFeed',
    data() {
        return {
            mediaItems: [],
            modal: {},
            modalMediaItem: {},
            resident: store.state.residents[store.state.activeResident],
            user: store.state.user,
            intervalId: null,
            fakerDemo: [
                'dante.meulemeester@gmail.com'
            ],
            showLoadMorePosts: true
        }
    },
    mounted() {

        // init materialize modals
        var elem = document.getElementById('confirm-delete');
        this.modal = M.Modal.init(elem);

        // When a user uploaded a video they see a message that it takes some time to upload video's
        // When the upload has been handled by the server we should update the post.
        // We can do it every 20 seconds for now, but this will probably need to change
        // this.intervalId = setInterval(() => {
        //     this.refreshPosts()
        // }, 20000)
        // store.state.echoClient.private('App.User.' + this.user.id)
        //     .listen('UpdatePosts', (e) => {
        //         store.dispatch('fetchActiveResidentPosts')
        //     })
    },
    computed: {
        posts() {
            return store.getters.postsSorted
        }
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.handleDebouncedScroll);

        clearInterval(this.intervalId)
    },
    methods: {
        // refreshPosts() {
        //     store.dispatch('fetchActiveResidentPosts').then(() => {
        //         this.posts = store.state.postsForActiveResident.sort((a, b) => {
        //             let dateA = a.created_at.replace(/ /g, 'T')
        //             let dateB = b.created_at.replace(/ /g, 'T')
        //             return new Date(dateB) - new Date(dateA)
        //         })
        //     })
        // },
        loadMorePosts() {
            // id of latest posts
            let latestPostId = this.posts[this.posts.length - 1].id
            // get x posts after this latest one
            axios.get(`${process.env.VUE_APP_SERVER_URL}/api/resident-fetch-extra-posts/${this.resident.id}/${latestPostId}`, {
                headers: {
                    'Authorization': 'Bearer ' + store.state.auth.auth_token
                }
            })
                .then((response) => {
                    console.log(response)
                    console.log(Object.values(response.data.posts))
                    if(Object.values(response.data.posts).length > 0) {
                        Object.values(response.data.posts).forEach(function (post) {
                            store.commit('ADD_POST_TO_STATE', post);
                        })
                    } else {
                        this.showLoadMorePosts = false
                    }
                })
                .catch((e) => {
                    console.error(e)
                })
        },
        confirmedDelete() {
            // delete the media item
            if(this.modalMediaItem.hasOwnProperty('id')) {
                axios.delete(`${process.env.VUE_APP_SERVER_URL}/api/delete-post/${this.modalMediaItem.id}`, {
                    headers: {
                        'Authorization': 'Bearer ' + store.state.auth.auth_token
                    }
                }).then((res) => {
                    // since we successfully deleted a post we can now
                    // commit to store to delete post with id modalMediaItem.id
                    store.commit('DELETE_POST_FROM_STATE', this.modalMediaItem.id)
                    this.modalMediaItem = {}
                    this.modal.close()
                })
                .catch((err) => {
                    console.error(err)
                })
            }
        },
        formattedDate(date) {
            // convert date to ISO standard. Otherwise Safari cannot interprete this.
            let thedate = date.replace(/ /g, 'T')
            return format(new Date(thedate), 'dd-MM-yyyy H:mm')
        },
        imageUrl(image) {
            return `${process.env.VUE_APP_SERVER_URL}/storage/${image.id}/${image.file_name}`
        },
        avatarUrl(image) {
            if(image !== undefined) {
                return `${process.env.VUE_APP_SERVER_URL}/storage/${image.id}/${image.file_name}`
            }
            return '';
        },
        confirmDelete(mediaItemId) {
            this.modalMediaItem = this.posts.find(item => {
                return mediaItemId == item.id
            })
            this.modal.open()

        },
        closeModalConfirmDelete() {
            this.modalMediaItem = {}
            this.modal.close()
        },
        isOwner(uploader) {
            return uploader == this.user.id
        }
    },
}
</script>

<style scoped>

.grid-container {
    display: grid;
    grid-template-columns: auto;
}

.item {
    padding: 3px;
    object-fit: fill;
}

.news-wrapper img {
    width: 100%;
    max-height: 250px;
    object-fit: scale-down;
}

.news-wrapper video {
    width: 100%;
    max-height: 250px;
}


.row {
    margin-bottom: 10px;
}

.news-wrapper {
    margin: auto;
    margin-bottom: 10px;
    width: 98%;
}

.author-name {
    font-weight: bold;
    font-size: 18px;
    margin-top: 15px;
    margin-bottom: 0px;
}

.time-indication {
}

.modal-option {
    cursor: pointer;
    text-transform: uppercase;
    text-align: center;
}

.modal-option-confirm {
    background-color: red !important;
    color: white;
}
</style>
